import moment from 'moment';
import { createContext } from 'react';
import parsePhoneNumber from 'libphonenumber-js'
const _ = require("lodash");

export const BaseContext = createContext();

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function escapeSelector(selector) {
    return _.replace(selector, new RegExp("\\.",'g'), "\\.");
}

export function getFormattedName(user) {
    return _.trim(`${user.firstName || ""} ${user.lastName || ""}`);
}

export function isInsideElement(element, x, y) {
    const top = parseFloat(element.offset().top - parseInt(element.css('padding-top')));
    const left = parseFloat(element.offset().left - parseInt(element.css('padding-left')));
    const bottom = top + parseFloat(element.height()) + parseInt(element.css('padding-bottom'));
    const right = left + parseFloat(element.width()) + parseInt(element.css('padding-right'));
    return (x >= left && x <= right && y >= top && y <= bottom);
}

export function getFormattedImageLink(link, prefix) {
    if (!link) {
        return link
    }

    if (link.startsWith("http")) {
        return link;
    } else {
        return prefix + link;
    }
}

export function currencyFormat(num, includeSign=true) {
    if (_.isNil(num)) {
        return "";
    }
    return (includeSign ? '$' : '') + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function hasAccess(feature, userInfo, mode="update", venueId=null) {
    if (_.isEmpty(userInfo)) {
        return false;
    }
    if (userInfo.accessLevel === 0) {
        return false;
    } else if (userInfo.accessLevel === 1) {
        return true;
    } else if (userInfo.accessLevel === 2) {
        return mode === "view" || mode === "viewWithFinancials";
    } else {
        const access = userInfo.customAdminAccess[feature]
        if (access === "update") {
            return true
        } else if (mode === 'view' && (access === 'view' || access === 'viewOnlyNoFinancials')) {
            return true
        } else if (mode === 'viewWithFinancials' && access === 'view') {
            return true
        } else if (feature === "booking" && userInfo.accessLevel === 3 && access === "no") {
            if (venueId != null) {
                // console.log("checking " + feature + ", " + mode + ", " + venueId);
                // debugger;
            }
            if (venueId === "any") {
                const levels = _.map(userInfo.customAdminAccess.venueSpecificAccess, (a) => a.accessLevel)
                if (mode === "update") {
                    return _.includes(levels, "update");
                } else if (mode === "view") {
                    return _.includes(levels, "update") || _.includes(levels, "view") || _.includes(levels, "viewOnlyNoFinancials");
                } else if (mode === "viewWithFinancials") {
                    return _.includes(levels, "update") || _.includes(levels, "view");
                } else {
                    return false
                }
            }
            // Check venue specific access for bookings
            if (!_.isNil(venueId)) {
                const vaccess = _.find(userInfo.customAdminAccess.venueSpecificAccess, (a) => String(a.venueId) === String(venueId));
                if (vaccess.accessLevel === "update") {
                    return true
                } else if (mode === "view" && (vaccess.accessLevel === "view" || vaccess.accessLevel === "viewOnlyNoFinancials")) {
                    return true
                } else if (mode === "viewWithFinancials" && vaccess.accessLevel === "view") {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }
}

export function isFullAdmin(userInfo) {
    return !_.isNil(userInfo.accessLevel) && !_.isUndefined(userInfo.accessLevel) && userInfo.accessLevel === 1;
}

export function isPartialOrFullAdmin(userInfo) {
    return !_.isNil(userInfo.accessLevel) && !_.isUndefined(userInfo.accessLevel) && userInfo.accessLevel > 0;
}

export function isNotAdmin(userInfo) {
    return !isPartialOrFullAdmin(userInfo);
}

export function getAccessibleVenues(venues, userInfo) {
    if (String(userInfo.accessLevel) === String(3)) {
        if (userInfo.customAdminAccess.booking !== "no") {
            return venues;
        } else {
            const vmap = userInfo.customAdminAccess.venueSpecificAccess;
            const vids = _.map(_.filter(vmap, (v) => v.accessLevel !== "no"), (a) => a.venueId);
            return _.filter(venues, (v) => _.includes(vids, v.id));
        }
    } else {
        return venues;
    }
}

export function validateEmail(email, isRequired) {
    if (isRequired) {
        if (!email || _.isEmpty(email.trim())) {
            return false;
        }
    } else {
        if (!email || _.isEmpty(email.trim())) {
            return true;
        }
    }
    const result = String(email.trim())
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    return result !== null;
}

export function validatePhone(phone, isRequired) {
    if (isRequired) {
        if (!phone || _.isEmpty(phone.trim())) {
            return false;
        }
    } else {
        if (!phone || _.isEmpty(phone.trim())) {
            return true;
        }
    }
    const phoneNumber = parsePhoneNumber(phone, 'US');
    if (phoneNumber) {
        return phoneNumber.isValid();
    } else {
        return false;
    }
}

export function getDatePickerDateFormatForFacility(facilitySettings) {
    if (facilitySettings.dateFormat === 2) {
        return "yy-dd-mm"
    } else {
        // Default it to format 1
        return "yy-mm-dd";
    }
}

export function getDateFormatForFacility(facilitySettings) {
    if (facilitySettings.dateFormat === 2) {
        return "ddd, MMM DD, YYYY"
    } else {
        // Default it to format 1
        return "ddd, DD MMM, YYYY"
    }
    /*
    { 'value': 1, 'label': 'Sat, Feb 06, 2016' },
    { 'value': 2, 'label': 'Sat, 06 Feb, 2016' },
    */
}

export function getShortDateFormatForFacility(facilitySettings) {
    if (facilitySettings.dateFormat === 2) {
        return "ddd, MMM DD"
    } else {
        // Default it to format 1
        return "ddd, DD MMM"
    }
    /*
    { 'value': 1, 'label': 'Sat, Feb 06, 2016' },
    { 'value': 2, 'label': 'Sat, 06 Feb, 2016' },
    */
}

export function getDateFormatWithTimeForFacility(facilitySettings) {
    if (facilitySettings.dateFormat === 2) {
        return "ddd, DD MMM, YYYY hh:mm:ss a";
    } else {
        return "ddd, MMM DD, YYYY hh:mm:ss a";
    }
}

export function cyrb53(str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
    h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1>>>0);
};

export function dateRangeClassCheck(date, startDate, endDate) {
    var sdate = startDate;
    var edate = endDate;
    var fdate = moment(date).format("YYYY-MM-DD");
    var className = ""
    if (sdate === fdate) {
        className = "ui-datepicker-start-date";
    } else if (edate === fdate) {
        className = "ui-datepicker-end-date";
    } else if (moment(edate).isAfter(sdate) && moment(sdate).isBefore(fdate, 'day') && moment(edate).isAfter(fdate, 'day')) {
        className = "ui-datepicker-mid-date";
    }
    return [true, className, null];
}

export function parseTime(timeString) {
    const timeComponents = timeString.split(':').map(component => parseInt(component, 10));
    
    if (timeComponents.length === 3 && 
        !isNaN(timeComponents[0]) && ( timeComponents[0] <= 24 && timeComponents[0] >= 0) && 
        !isNaN(timeComponents[1]) && (timeComponents[1] <= 59 && timeComponents[1] >= 0) && 
        !isNaN(timeComponents[2]) && ( timeComponents[2] == 0)) {
        
        if (timeComponents[0] == 24 && (timeComponents[1] > 0 || timeComponents[2] > 0)) {
            return null
        }

        const date = new Date();
        date.setUTCHours(parseInt(timeComponents[0], 10));
        date.setUTCMinutes(parseInt(timeComponents[1], 10));
        date.setUTCSeconds(parseInt(timeComponents[2], 10));

        if (!isNaN(date.getTime())) {
            const time = date.toISOString().substring(11, 19)
            if (time == '24:00:00') {
                return '00:00:00'
            }
            return time;
        }
    }
    return null;
}

export function validateDate(dateString) {
    const dateRegex = /(\d{4})-(\d{2})-(\d{2})/;
    if (!dateRegex.test(dateString)) return false;
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime());
}

export function downloadBlob(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}

export function getRandomString(length) {
    var text = "";
    var charset = "abcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
        text += charset.charAt(Math.floor(Math.random() * charset.length));

    return text;
}

export function conciseTimeDisplay(mtime, withMeridiem = true) {
    if (mtime.minutes() !== 0) {
        return mtime.format(`h:mm${withMeridiem ? "a" : ""}`);
    } else {
        return mtime.format(`h${withMeridiem ? "a" : ""}`);
    }
}

export function getColorOptions(t) {
    return [
        { "value" : "#6d8bbf", "label" : t('colors.blue'), "isDefault" : true },
        { "value" : "#5484ed", "label" : t('colors.bold_blue') },
        { "value" : "#a4bdfc", "label" : t('colors.light_blue') },
        { "value" : "#afeeee", "label" : t('colors.pale_aquamarine') },
        { "value" : "#7fffd4", "label" : t('colors.aquamarine') },
        { "value" : "#46d6db", "label" : t('colors.turquiose') },
        { "value" : "#add6a7", "label" : t('colors.dry_green') },
        { "value" : "#7bd148", "label" : t('colors.green') },
        { "value" : "#51b749", "label" : t('colors.bold_green') },
        { "value" : "#f9e79f", "label" : t('colors.chiffon') },
        { "value" : "#fbd75b", "label" : t('colors.yellow') },
        { "value" : "#ffb878", "label" : t('colors.orange') },
        { "value" : "#ffa500", "label" : t('colors.dark_orange') },
        { "value" : "#ff887c", "label" : t('colors.red') },
        { "value" : "#dc2127", "label" : t('colors.bold_red') },
        { "value" : "#e1e1e1", "label" : t('colors.gray') },
        { "value" : "#6d5cae", "label" : t('colors.blueberry') },
        { "value" : "#4b0082", "label" : t('colors.indigo') },
        { "value" : "#48b0f7", "label" : t('colors.peacock') },
        { "value" : "#0da899", "label" : t('colors.basil') },
        { "value" : "#c9a843", "label" : t('colors.sun') },
        { "value" : "#fdbeb9", "label" : t('colors.flamingo') },
        { "value" : "#f7460f", "label" : t('colors.tangerine') },
        { "value" : "#b03e3b", "label" : t('colors.maroon') },
        { "value" : "#626c75", "label" : t('colors.graphite') },
        { "value" : "#e8daef", "label" : t('colors.light_purple') },
        { "value" : "#ff00ff", "label" : t('colors.fushia') },
        { "value" : "#808000", "label" : t('colors.olive') },
    ];
}

export function getDefaultColor(t) {
    const options = getColorOptions(t);
    for (const op of options) {
        if (op['isDefault']) {
            return op['value'];
        }
    }
    return null;
}

export function getPaymentOptions() {
    return [
        { 'id': "unpaid", 'label': 'Unpaid', 'icon': 'fa-undo' },
        { 'id': "noCharge", 'label': 'No Charge', 'icon': 'fa-ban' },
        { 'id': "hold", 'label': 'Hold', 'icon': 'fa-pause' },
        { 'id': "request", 'label': 'Request', 'icon': 'fa-flag' },
        { 'id': "account", 'label': 'Account', 'icon': 'fa-user' },
        { 'id': "paid", 'label': 'Paid', 'icon': 'fa-check-circle' },
        { 'id': "paidCash", 'label': 'Paid Cash', 'icon': 'fa-money' },
        { 'id': "paidCheque", 'label': 'Paid Cheque', 'icon': 'fa-edit' },
        { 'id': "paidDebit", 'label': 'Paid Debit', 'icon': 'fa-credit-card' },
        { 'id': "paidEFT", 'label': 'Paid EFT', 'icon': 'fa-user' },
        { 'id': "paidCreditOffline", 'label': 'Paid Credit Offline', 'icon': 'fa-credit-card' },
        { 'id': "requestCC", 'label': 'Request CC Payment', 'icon': 'fa-credit-card' },
        { 'id': "stripe", 'label': 'Paid Credit Online', 'icon': 'fa-credit-card' },
        { 'id': "paidCredit", 'label': 'Paid Credit Balance', 'icon': 'fa-credit-card' },
        { 'id': "onlineBooking", 'label': 'Online Booking', 'icon': 'fa-star' },
        { 'id': "requestAdmin", 'label': 'Request Admin', 'icon': 'fa-flag' },
        { 'id': "requestUser", 'label': 'Request User', 'icon': 'fa-flag' },
        { 'id': "ccOnlineAdmin", 'label': 'CC Online Admin', 'icon': 'fa-credit-card' },
        { 'id': "ccOnlineUser", 'label': 'CC Online User', 'icon': 'fa-credit-card' },
    ];
}

export function getCCFeeUsageOptions(t, allowClasses) {
    let options = [
        { 'id': 'booking', 'label': t("payment_settings.settings.added_fees_usage_bookings") },
        { 'id': 'invoice', 'label': t("payment_settings.settings.added_fees_usage_invoices") },
        { 'id': 'agreement', 'label': t("payment_settings.settings.added_fees_usage_agreements") },
    ]

    if (allowClasses) {
        options.push({ 'id': 'class', 'label': t("payment_settings.settings.added_fees_usage_classes") })
    }
    return options
}

export function getLimitOptions() {
    return [
        { 'id': "20", 'label': '20' },
        { 'id': "50", 'label': '50' },
        { 'id': "100", 'label': '100' },
        { 'id': "200", 'label': '200' },
    ];
}

const dayOfWeekOptions = {
    1: 'days.monday',
    2: 'days.tuesday',
    3: 'days.wednesday',
    4: 'days.thursday',
    5: 'days.friday',
    6: 'days.saturday',
    0: 'days.sunday'
}

export function getNameForDayOfWeek(t, i) {
    return t(dayOfWeekOptions[i]);
}

export function getDayOptions(t) {
    return [
        { 'value': '1', 'label': t('days.monday') },
        { 'value': '2', 'label': t('days.tuesday') },
        { 'value': '3', 'label': t('days.wednesday') },
        { 'value': '4', 'label': t('days.thursday') },
        { 'value': '5', 'label': t('days.friday') },
        { 'value': '6', 'label': t('days.saturday') },
        { 'value': '0', 'label': t('days.sunday') }
    ]
}

export function renderChildren(props) {
    if (typeof props.children === 'function') {
        return props.children();
    } else {
        return props.children;
    }
}

export function getFilteredPaymentMethods(paymentMethods, companySettings, venue, userType) {
    if (companySettings.paymentProcessor !== "PAY_TRACE") {
        paymentMethods = _.filter(paymentMethods, (pm) => pm.paymentTag !== "requestWithCard");
        paymentMethods = _.filter(paymentMethods, (pm) => pm.paymentTag !== "refund");
    }
    if (!companySettings.enableCredit) {
        paymentMethods = _.filter(paymentMethods, (pm) => pm.paymentTag !== "paidCredit");
    }
    if (companySettings.showCreditCardOnline === "yes") {
        // Nothing to do. Show CC online
    } else if (companySettings.showCreditCardOnline === "no") {
        // Don't show credit card to anyone
        paymentMethods = _.filter(paymentMethods, (pm) => pm.paymentTag !== "paidCreditOnline");
    } else if (companySettings.showCreditCardOnline === "adminOnly" && userType !== "admin") {
        // Show to admin only but user is not admin
        paymentMethods = _.filter(paymentMethods, (pm) => pm.paymentTag !== "paidCreditOnline");
    } else if (companySettings.showCreditCardOnline === "userOnly" && userType !== "user") {
        // Show to user only but user is not user
        paymentMethods = _.filter(paymentMethods, (pm) => pm.paymentTag !== "paidCreditOnline");
    }
    if (venue && !venue.ccPaymentRequest) {
        paymentMethods = _.filter(paymentMethods, (pm) => pm.paymentTag !== "requestCC");
    }
    return paymentMethods;
}

export function getFormFieldOptions() {
    return [
        { 'label': 'Text', 'value': 'text' },
        { 'label': 'Choice', 'value': 'select' },
        { 'label': 'Date', 'value': 'date' },
        { 'label': 'Phone', 'value': 'phone' },
        { 'label': 'Number', 'value': 'float' },
        { 'label': 'Email', 'value': 'email' },
        { 'label': 'Agree', 'value': 'agree' },
        { 'label': 'Agree/Disagree', 'value': 'agreeOrDisagree' },
    ]
}

export function getFormFieldLabel(value) {
    const options = getFormFieldOptions();
    for (const op of options) {
        if (op['value'] === value) {
            return op['label'];
        }
    }
    return null;
}

export function getDescriptionForRepeatBookings(t, repeatConfig) {
    if (!repeatConfig) {
        return "No"
    } else {
        let endString = "";
        if (repeatConfig.endDate) {
            endString = `Until ${ moment(repeatConfig.endDate).format("ddd, DD MMMM, YYYY") }`;
        } else {
            endString = `${ repeatConfig.occurrence } times starting from ${ moment(repeatConfig.startDate).format("ddd, DD MMMM, YYYY") }`;
        }
        if (repeatConfig.repeatType === "week") {
            const dayNames = _.map(repeatConfig.on, (i) => getNameForDayOfWeek(t, i))
            return `Weekly on ${ dayNames.join(", ")}, ${ endString }`;
        } else if (repeatConfig.repeatType === "day") {
            const repeatString = repeatConfig.repeatInterval > 1 ? `${ repeatConfig.repeatInterval } days`: `day`;
            return `Every ${ repeatString }, ${ endString }`;
        } else if (repeatConfig.repeatType === "month") {
            const repeatString = repeatConfig.repeatInterval > 1 ? `${ repeatConfig.repeatInterval } months`: `month`;
            return `Every ${ repeatString }, ${ endString }`;
        }
    }
}

export function getStripePublishableKeyForFacility(settings) {
    if (settings.helcimLive) {
        return settings.stripePubKey;
    } else {
        return settings.stripeTestPubKey;
    }
}

export function getPaymentProcessorName(paymentProcessor) {
    const map = {
        "STRIPE": "Stripe",
        "BEANSTREAM": "Bambora",
        "SPORTSPAY": "Sportspay",
    }
    if (_.has(map, paymentProcessor)) {
        return map[paymentProcessor]
    } else {
        return paymentProcessor;
    }
}

export function getEventTypesForVenue(allEventTypes, venueId) {
    const filteredEventTypes = _.filter(allEventTypes, (type, i) => String(type.rinkId) === String(venueId) || _.includes(type.inheritedToVenueIds, parseInt(venueId)));
    const sortedEventTypes = _.sortBy(filteredEventTypes,  [(et) => String(et.rinkId) !== String(venueId), (et) => et.sort]);
    return sortedEventTypes;
}

export function GetShortedName(value) {
    if (value.length < 10) {
        return value;
    }
    return value.substring(0, 10) + "...";
}

export function getErrorMessageFromPartnerMapping(mapping) {
    if (mapping.status === "success") {
        return null;
    }
    let errorMessage = "Unable to push booking"
    if (mapping.errorMessage) {
        try {
            const jsonErrorMessage = JSON.parse(mapping.errorMessage);
            if (jsonErrorMessage && jsonErrorMessage.responseBody) {
                try {
                    const responseMessage = JSON.parse(jsonErrorMessage.responseBody);
                    if (responseMessage && responseMessage.error) {
                        errorMessage = responseMessage.error
                    }
                } catch (e) {
                    errorMessage = jsonErrorMessage.responseBody;
                    console.error(e); // error in the above string (in this case, yes)!
                }
            }
        } catch (e) {
            console.error(e); // error in the above string (in this case, yes)!
        }
    }
    return errorMessage;
}
