import '../App.scss';
import '../css/modals.scss';
import React, { useState, useEffect, createRef, useContext, useImperativeHandle, forwardRef } from 'react';
import { serverFetch, serverPost, serverPatch } from '../helpers/server';
import { BaseContext } from '../helpers/common';
import BaseForm from './BaseForm';
import Loader from '../components/Loader';
import { Row, Col } from 'react-bootstrap';
import StripeCardInput from './StripeCardInput';
import BamboraCardInput from './BamboraCardInput';
import SportsPayCardInput from './SportsPayCardInput';
import { useTranslation } from 'react-i18next';
const _ = require('lodash')

const BookingPaymentInput = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        getPaymentMethod(data) {
            return getPaymentMethod(data);
        },
        onFieldChange(name, value) {
            onFieldChange(name, value);
        }
    }));

    const { t } = useTranslation('common');
    const cardRef = createRef();
    const { getApiUrl, settings } = useContext(BaseContext);
    const [prefix, setPrefix] = useState("");
    const [name, setName] = useState("");
    const [initialFields, setInitialFields] = useState({});
    const [paymentSources, setPaymentSources] = useState([]);
    const [paymentSourcesLoading, setPaymentSourcesLoading] = useState(true);
    const [selectedPaymentSource, setSelectedPaymentSource] = useState(null);
    const [paymentIntent, setPaymentIntent] = useState({});
    const paymentRef = createRef();

    useEffect(() => {
        setPrefix(props.prefix);
    }, [props.prefix]);

    useEffect(() => {
        if (props.groupId) {
            serverFetch(getApiUrl(`/user_groups/${props.groupId}/payment_sources`), { skipCache: true }).then((res) => {
                setPaymentSourcesLoading(false);
                if (res) {
                    setPaymentSources(res);
                }
            })
        } else {
            setPaymentSourcesLoading(false);
        }
    }, [props.groupId])
    
    useEffect(() => {
        if (props.total) {
            serverPost(getApiUrl(`/bookings/payment_intent`), { amount: props.total, groupId: props.groupId }).then((res) => {
                setPaymentIntent(res);
            });
        }
    }, [setPaymentIntent, getApiUrl, props.groupId, props.total]);

    const onFieldChange = (name, value) => {
        if (name === "paymentSource") {
            setSelectedPaymentSource(value);
        } else if (cardRef.current) {
            cardRef.current.onFieldChange(name, value);
        }
    }

    const paymentSourcesOptions = _.map(paymentSources, (ps) => {
        const card = ps.card;
        const cardString = <span><strong style={{textTransform:"uppercase"}}>{card.brand}</strong> xxxx-xxxx-xxxx-{card.last4} Exp {_.padStart(card.expMonth, 2, "0")}/{card.expYear}</span>;
        return { value: ps.id, label: cardString };
    })
    paymentSourcesOptions.push({ value: null, label: "New Card"})

    const getPaymentMethod = async (data) => {
        if (!_.isNil(selectedPaymentSource)) {
            return {
                paymentMethodId: _.find(paymentSources, (ps) => String(ps.id) === selectedPaymentSource).id,
                saveCard: false
            };
        } else if (cardRef.current) {
            const result = await cardRef.current.getPaymentMethod(data)
            if (result) {
                result.saveCard = data.save_card || false;
                result.cardHolderName = data.cardHolderName;
                return result;
            } else if (result && result.errors) {
                if (props.setErrorFields) {
                    props.setErrorFields(result.errors);
                }
                return
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    const renderPaymentProcessorInput = () => {
        if (settings && settings.paymentProcessor === "NONE") {
            return <></>;
        } else if (settings && settings.paymentProcessor === "STRIPE") {
            if (!selectedPaymentSource) {
                return (
                    <StripeCardInput ref={cardRef} setInitialFields={props.setInitialFields} />
                )
            } else {
                return null;
            }
        } else if (settings && settings.paymentProcessor === "BEANSTREAM") {
            if (!selectedPaymentSource) {
                return (
                    <BamboraCardInput ref={cardRef} setInitialFields={props.setInitialFields} />
                )
            } else {
                return null;
            }
        } else if (settings && settings.paymentProcessor === "SPORTSPAY") {
            if (!selectedPaymentSource) {
                return !(paymentIntent && paymentIntent.secret) ? null : (
                    <SportsPayCardInput
                        ref={cardRef} setInitialFields={props.setInitialFields}
                        apiKey={paymentIntent && paymentIntent.secret}
                        host={paymentIntent && paymentIntent.host}
                    />
                )
            } else {
                return null;
            }
        } else {
            return (
                <p>{t('payment_modal.provider_not_supported', { paymentProcessor: settings && settings.paymentProcessor })}</p>
            )
        }
    }

    return (
        <div>
            <Loader loading={paymentSourcesLoading}>
                <Row>
                    {
                        !_.isEmpty(paymentSources) &&
                        <BaseForm.Input colSpan="7" name="paymentSource" type="select" options={paymentSourcesOptions}
                                        label={t('payment_modal.select_payment_method')} showSearch={false}/>
                    }
                </Row>
                { !selectedPaymentSource && renderPaymentProcessorInput() }
            </Loader>
        </div>
    )

})

export default BookingPaymentInput;
