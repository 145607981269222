import '../../App.scss';
import '../../css/modals.scss';
import React, {useState, useEffect, createRef, useContext, forwardRef, useImperativeHandle} from 'react';
import { serverFetch, serverPost, serverPatch } from '../../helpers/server';
import { BaseContext, currencyFormat } from '../../helpers/common';
import BaseForm from '../BaseForm';
import BaseModal from './BaseModal';
import SubmitButton from '../SubmitButton';
import { Row, Col } from 'react-bootstrap';
import StripePaymentInput from '../StripePaymentInput';
import { useTranslation } from 'react-i18next';
import Notification from "../Notification";
import BamboraCardInput from "../BamboraCardInput";
import SportsPayCardInput from "../SportsPayCardInput";
import payment from "../../pages/setting/Payment";
const _ = require('lodash')

class InvoiceDataManager {
    constructor(invoice) {
        this.invoice = invoice
    }

    getAmountDue() {
        return this.invoice.amountDue;
    }

    getValueWithFee(amount) {
        return (parseFloat(amount) + parseFloat(this.getFee(amount, this.invoice))).toFixed(2);
    }

    hasFee() {
        return this.invoice ?
            (this.invoice.invoiceOnlinePaymentOption === "yesWithFee" || this.invoice.invoiceOnlinePaymentOption === "yesPartialFee")
            : false;
    }

    getFee(amount) {
        if (!this.invoice) {
            return "0.00";
        }
        const hasFee = this.hasFee();
        if (hasFee) {
            return (parseFloat(amount) * parseFloat(this.invoice.invoiceFeePercentage)/100).toFixed(2)
        }
        return "0.00";
    }

    getFeePercentage() {
        if (!this.invoice) {
            return 0;
        }
        const hasFee = this.hasFee();
        if (hasFee) {
            return this.invoice.invoiceFeePercentage;
        }
        return 0;
    }

    hasCCFee() {
        return this.invoice ?
            _.includes(this.invoice.creditCardFeeUsage, "invoice")
            : false;
    }

    getCCFee(amount) {
        if (!this.invoice) {
            return "0.00";
        }
        const hasCCFee = this.hasCCFee();
        if (hasCCFee) {
            return ((parseFloat(amount) * parseFloat(this.invoice.creditCardFeePercent)/100) + parseFloat(this.invoice.creditCardFeeBaseAmountInCents/100)).toFixed(2);
        }
        return "0.00";
    }

    getCCFeePercentage() {
        if (!this.invoice) {
            return 0;
        }
        const hasCCFee = this.hasCCFee();
        if (hasCCFee) {
            return this.invoice.creditCardFeePercent;
        }
        return 0;
    }

    getCCMinAmount() {
        if (!this.invoice) {
            return 0;
        }
        const hasCCFee = this.hasCCFee();
        if (hasCCFee) {
            return this.invoice.creditCardMinAmountInCents/100;
        }
        return 0;
    }

    getAllowsEditing() {
        return this.invoice ?
            (this.invoice.invoiceOnlinePaymentOption === "yesPartial" || this.invoice.invoiceOnlinePaymentOption === "yesPartialFee")
            : false;
    }

    getPaymentIntentSubUrl() {
        return `/invoices/${this.invoice.uuid}/payment_intent`
    }

    getGroupId() {
        return this.invoice.group.id
    }

    getUUID() {
        return this.invoice.uuid;
    }
}

class AgreementDataManager {
    constructor(agreement) {
        this.agreement = agreement
    }

    getValueWithFee(amount) {
        return (parseFloat(amount) + parseFloat(this.getFee(amount, this.agreement))).toFixed(2);
    }

    getAmountDue() {
        return this.agreement.amountDue;
    }

    hasFee() {
        return false;
    }

    getFee() {
        return "0.00";
    }

    getFeePercentage() {
        return 0;
    }

    hasCCFee() {
        return this.agreement ?
            _.includes(this.agreement.creditCardFeeUsage, "agreement")
            : false;
    }

    getCCFee(amount) {
        if (!this.agreement) {
            return "0.00";
        }
        const hasCCFee = this.hasCCFee();
        if (hasCCFee) {
            return ((parseFloat(amount) * parseFloat(this.agreement.creditCardFeePercent)/100) + parseFloat(this.agreement.creditCardFeeBaseAmountInCents/100)).toFixed(2);
        }
        return "0.00";
    }

    getCCFeePercentage() {
        if (!this.agreement) {
            return 0;
        }
        const hasCCFee = this.hasCCFee();
        if (hasCCFee) {
            return this.agreement.creditCardFeePercent;
        }
        return 0;
    }

    getCCMinAmount() {
        if (!this.agreement) {
            return 0;
        }
        const hasCCFee = this.hasCCFee();
        if (hasCCFee) {
            return this.agreement.creditCardMinAmountInCents/100;
        }
        return 0;
    }

    getAllowsEditing() {
        return this.agreement ? (this.agreement.agreementOnlinePaymentOption === "yesPartial"): false;
    }

    getPaymentIntentSubUrl() {
        return `/agreements/${this.agreement.uuid}/payment_intent`
    }

    getGroupId() {
        return this.agreement.group.id
    }

    getUUID() {
        return this.agreement.uuid;
    }
}

function InvoiceAgreementPaymentModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [settings, setSettings] = useState({});
    const [invoice, setInvoice] = useState({});
    const [agreement, setAgreement] = useState({});
    const [initialFields, setInitialFields] = useState({});
    const [paymentSources, setPaymentSources] = useState([]);
    const [selectedPaymentSource, setSelectedPaymentSource] = useState(null);
    const [total, setTotal] = useState(null);
    const [amountBeforeFees, setAmountBeforeFees] = useState(null);
    const [dataManager, setDataManager] = useState(null);
    const [feeAmount, setFeeAmount] = useState(null);
    const [ccFeeAmount, setCCFeeAmount] = useState("");
    const [errorFields, setErrorFields] = useState(null);
    const [showCCFees, setShowCCFees] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const paymentRef = createRef();
    const partsRef = createRef();

    useEffect(() => {
        setSettings(props.settings);
    }, [props.settings])

    useEffect(() => {
        const isInv = _.isNil(props.agreement);
        setInvoice(props.invoice);
        setAgreement(props.agreement);

        let dm = null;
        if (isInv) {
            dm = new InvoiceDataManager(props.invoice);
        } else {
            dm = new AgreementDataManager(props.agreement);
        }
        const newTotal = dm.getValueWithFee(dm.getAmountDue());
        setInitialFields({
            amount: dm.getAmountDue(),
            fee: dm.getFeePercentage(),
            creditCardFeeAmount: dm.getCCFee(newTotal), //TODO(aswin): investigate about using newTotal
            totalWithFee: (parseFloat(newTotal) + parseFloat(dm.getCCFee(newTotal))).toFixed(2),
            total: newTotal
        })
        setTotal(newTotal);
        setAmountBeforeFees(dm.getAmountDue());
        setFeeAmount(dm.getFee(dm.getAmountDue()));
        setCCFeeAmount(dm.getCCFee(newTotal));
        setDataManager(dm);
        setShowCCFees(dm.hasCCFee() && (parseFloat(newTotal) >= dm.getCCMinAmount()))
    }, [props.invoice, props.agreement])

    useEffect(() => {
        if (props.show && dataManager) {
            serverFetch(getApiUrl(`/user_groups/${dataManager.getGroupId()}/payment_sources`), { suppressUnauthenticated: true, skipCache: true }).then((res) => {
                setPaymentSources(res);
            })
        }
    }, [props.show])

    const onFieldChange = (name, value) => {
        if (name === "amount") {
            if (value === "") {
                value = 0;
            }
            let newTotal = dataManager.getValueWithFee(value);
            let newFeeAmount = dataManager.getFee(value);
            let newCCFeeAmount = dataManager.getCCFee(value);
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields.amount = value;
                newFields.fee = dataManager.getFeePercentage();
                newFields.creditCardFeeAmount = newCCFeeAmount;
                newFields.totalWithFee = (parseFloat(newTotal) + parseFloat(newCCFeeAmount)).toFixed(2);
                newFields.total = newTotal;
                return newFields;
            });
            setAmountBeforeFees(value);
            setTotal(newTotal);
            setFeeAmount(newFeeAmount);
            setCCFeeAmount(newCCFeeAmount);
            setShowCCFees(dataManager.hasCCFee() && (parseFloat(newTotal) >= dataManager.getCCMinAmount()));
        } else if (name === "paymentSource") {
            setSelectedPaymentSource(value);
            if (partsRef.current) {
                partsRef.current.onPaymentSourceSelected(value);
            }
        }

        if (partsRef.current) {
            partsRef.current.onFieldChange(name, value);
        }
    }

    const onPay = async (data) => {
        data.feeAmount = (feeAmount && feeAmount !== "") ? parseFloat(feeAmount) : 0;
        data.ccFeeAmount = showCCFees ? parseFloat(ccFeeAmount) : 0;
        if (partsRef.current) {
            setErrorMessage(null);
            await partsRef.current.onSubmit(data);
        }
    }

    const onPayError = async (response, errorMessage) => {
        setErrorMessage(errorMessage.card);
    }

    const onConfirmPay = async (paymentMethodData, payUrl) => {
        const paymentData = {
            ...paymentMethodData,
            amount: amountBeforeFees,
            invoiceFee: feeAmount,
        }
        if (ccFeeAmount > 0) {
            paymentData["ccFee"] = ccFeeAmount;
        }

        const paymentResult = await serverPost(getApiUrl(payUrl), paymentData, {}, onPayError);
        if (paymentResult) {
            setTimeout(() => {
                Notification.Show("Payment Successful");
            }, 100)
            if (props.onClose) {
                props.onClose();
            }
        }
    }

    const paymentSourcesOptions = _.map(paymentSources, (ps) => {
        const card = ps.card;
        const cardString = <span><strong style={{textTransform:"uppercase"}}>{card.brand}</strong> xxxx-xxxx-xxxx-{card.last4} Exp {_.padStart(card.expMonth, 2, "0")}/{card.expYear}</span>;
        return { value: ps.id, label: cardString };
    })
    paymentSourcesOptions.push({ value: null, label: "New Card"})

    const hasFee = dataManager && dataManager.hasFee();
    const allowEditing = dataManager && dataManager.getAllowsEditing();
    if (settings && _.includes(["STRIPE", "BEANSTREAM", "SPORTSPAY"], settings.paymentProcessor)) {
        return (
            <BaseModal {...props}>
                <BaseModal.Header>
                    {
                        _.isNil(props.agreement) ?
                        "Pay Invoice - " + (invoice && invoice.invoiceNumber)
                        : "Pay Agreement - " + (agreement && agreement.agreementNumber)
                    }
                </BaseModal.Header>
                <BaseForm onSubmit={onPay} onFieldChange={onFieldChange} initialFormFields={initialFields} errorFields={errorFields}>
                    <BaseModal.Body>
                        <Row>
                        <BaseForm.Input colSpan="4" type="number" step="0.01" name="amount" label="Amount" leftContent="$"
                            min="0" max={dataManager.getAmountDue()} readOnly={!allowEditing} disabled={!allowEditing}
                            validations={{ required: true, min: 0.01, max: dataManager.getAmountDue() }}/>
                        {
                            hasFee &&
                                <>
                                    <BaseForm.Input colSpan="4" type="number" step="0.01" name="fee" label="Fee"
                                        readOnly={true} rightContent="%"/>
                                    <BaseForm.Input colSpan="4" type="number" step="0.01" name="total" label="Total"
                                        readOnly={true} leftContent="$"/>
                                </>
                        }
                        </Row>
                        {
                            showCCFees &&
                            <Row>
                                <BaseForm.Input colSpan="6" type="number" step="0.01" name="creditCardFeeAmount" label="Credit Card Fee"
                                    readOnly={true} leftContent="$"/>
                                <BaseForm.Input colSpan="6" type="number" step="0.01" name="totalWithFee" label="Total with Fee"
                                    readOnly={true} leftContent="$"/>
                            </Row>
                        }
                        {
                            !_.isEmpty(paymentSources) &&
                                <Row>
                                    <BaseForm.Input name="paymentSource" type="select" options={paymentSourcesOptions}
                                        label="Select payment method" showSearch={false}/>
                                </Row>
                        }
                        <div className="cc_card_payment_intent">
                            {
                                settings.paymentProcessor === "STRIPE" &&
                                    <StripeParts
                                        {...props}
                                        ref={partsRef}
                                        total={total}
                                        setInitialFields={setInitialFields}
                                        dataManager={dataManager}
                                        onConfirmPay={onConfirmPay}
                                    />
                            }
                            {
                                settings.paymentProcessor === "BEANSTREAM" &&
                                    <BamboraParts
                                        {...props}
                                        ref={partsRef}
                                        total={total}
                                        setInitialFields={setInitialFields}
                                        dataManager={dataManager}
                                    />
                            }
                            {
                                settings.paymentProcessor === "SPORTSPAY" &&
                                    <SportsPayParts
                                        {...props}
                                        ref={partsRef}
                                        total={total}
                                        setInitialFields={setInitialFields}
                                        setErrorFields={setErrorFields}
                                        dataManager={dataManager}
                                    />
                            }
                        </div>
                    </BaseModal.Body>
                    <BaseModal.Footer>
                        <Row>
                            {
                                errorMessage &&
                                    <Col md="12" className="text-end">
                                        <div className="form-error-message">{ errorMessage }</div>
                                    </Col>
                            }
                            {
                                <Col md="12" className="text-end">
                                    <SubmitButton variant="primary" disabled={total === "0.00"}>Pay Now</SubmitButton>
                                </Col>
                            }
                        </Row>
                    </BaseModal.Footer>
                </BaseForm>
            </BaseModal>
        );
    } else {
        return (
            <BaseModal {...props}>
                <BaseModal.Header>
                    <BaseModal.Title>{t('common.edit')} Provider not supported</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    The provider { settings && settings.paymentProcessor } is not supported. Please contact your company admin.
                </BaseModal.Body>
                <BaseModal.Footer>
                </BaseModal.Footer>
            </BaseModal>
        )
    }
}

const StripeParts = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        async onSubmit(data) {
            await onSubmit(data);
        },

        onFieldChange(name, value) {
            onFieldChange(name, value)
        },

        onPaymentSourceSelected(selectedPaymentSource) {
            onPaymentSourceSelected(selectedPaymentSource);
        },
    }));

    const {getApiUrl} = useContext(BaseContext);
    const [paymentIntent, setPaymentIntent] = useState(null);
    const [total, setTotal] = useState(props.total);
    const [selectedPaymentSource, setSelectedPaymentSource] = useState(null);
    const [dataManager, setDataManager] = useState(null);
    const paymentRef = createRef();

    useEffect(() => {
        setDataManager(props.dataManager);
    }, [props.dataManager])

    useEffect(() => {
        setTotal(props.total);
    }, [props.total, props.totalWithFee])

    const onFieldChange = (name, value) => {

    }

    const onPaymentSourceSelected = (source) => {
        setSelectedPaymentSource(source);
    }

    const onSubmit = async (data) => {
        if (paymentRef.current) {
            await paymentRef.current.onSubmit(data);
        }
    }

    const onPay = async (paymentMethod) => {
        if (props.onConfirmPay) {
            const isInv = _.isNil(props.agreement);
            const payUrl = isInv ? `/invoices/${dataManager.getUUID()}/online_pay` : `/agreements/${dataManager.getUUID()}/online_pay`;
            await props.onConfirmPay({
                paymentProcessorData: {
                    stripe: {
                        paymentMethodId: paymentMethod.paymentMethodId,
                    }
                },
                paymentProcessor: "stripe",
            }, payUrl);
        }
    }

    return (
        <StripePaymentInput ref={paymentRef} onPay={onPay}
                            paymentSource={selectedPaymentSource} size="lg" {...props} total={total} />
    )
})

const BamboraParts = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        async onSubmit(data, callback) {
            await onSubmit(data, callback);
        },

        onFieldChange(name, value) {
            onFieldChange(name, value)
        },

        onPaymentSourceSelected(selectedPaymentSource) {
            onPaymentSourceSelected(selectedPaymentSource);
        },
    }));

    const {getApiUrl} = useContext(BaseContext);
    const [dataManager, setDataManager] = useState(null);
    const [total, setTotal] = useState(null);
    const [selectedPaymentSource, setSelectedPaymentSource] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const [error, setError] = useState(false);
    const cardRef = createRef();

    useEffect(() => {
        setDataManager(props.dataManager);
    }, [props.dataManager])

    useEffect(() => {
        setTotal(props.total);
    }, [props.total])

    useEffect(() => {
        if (props.show) {
            setInitialized(true)
        }
    }, [props.show])

    const onFieldChange = (name, value) => {
        if (cardRef.current) {
            cardRef.current.onFieldChange(name, value)
        }
    }

    const onPaymentSourceSelected = (source) => {
        setSelectedPaymentSource(source);
    }

    const onSubmit = async (data, callback) => {
        if (cardRef.current) {
            setError(false);
            const result = await cardRef.current.getPaymentMethod(data);
            if (!result) {
                setError("Card information is incomplete or incorrect. Please update the card details.");
                console.log("Handle error in getting payment method");
                return
            }

            let dm = null;
            const isInv = _.isNil(props.agreement);
            if (isInv) {
                dm = new InvoiceDataManager(props.invoice);
            } else {
                dm = new AgreementDataManager(props.agreement);
            }
            const paymentData = {
                paymentProcessorData: {
                    bambora: {
                        ...result,
                    }
                },
                amount: data.total || data.amount,
                paymentProcessor: "beanstream",
                cardHolder: result.cardHolderName,
                invoiceFee: data.feeAmount
            }
            if (data.ccFeeAmount > 0) {
                paymentData["ccFee"] = data.ccFeeAmount;
            }

            const payUrl = isInv ? `/invoices/${dm.getUUID()}/online_pay` : `/agreements/${dm.getUUID()}/online_pay`;
            const payResult = await serverPost(getApiUrl(payUrl), paymentData);
            if (payResult) {
                setTimeout(() => {
                    Notification.Show("Payment Successful");
                }, 100)
            } else {
                setError("Payment failed. Please update the card details");
            }
        }
    }

    return (
        <>
            <BamboraCardInput ref={cardRef} setInitialFields={props.setInitialFields} />
            {
                error &&
                    <span className="error">{ error }</span>
            }
        </>
    )
})

const SportsPayParts = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        async onSubmit(data, callback) {
            await onSubmit(data, callback);
        },

        onFieldChange(name, value) {
            onFieldChange(name, value)
        },

        onPaymentSourceSelected(selectedPaymentSource) {
            onPaymentSourceSelected(selectedPaymentSource);
        },
    }));

    const {getApiUrl} = useContext(BaseContext);
    const [dataManager, setDataManager] = useState(null);
    const [total, setTotal] = useState(null);
    const [paymentIntent, setPaymentIntent] = useState(null);
    const [selectedPaymentSource, setSelectedPaymentSource] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const [error, setError] = useState(false);
    const cardRef = createRef();

    useEffect(() => {
        setDataManager(props.dataManager);
    }, [props.dataManager])

    useEffect(() => {
        setTotal(props.total);
    }, [props.total])

    useEffect(() => {
        if (props.show) {
            setInitialized(true)
        }
    }, [props.show])

    useEffect(() => {
        if (props.show && dataManager) {
            serverPost(getApiUrl(dataManager.getPaymentIntentSubUrl())).then((res) => {
                if (res) {
                    setPaymentIntent(res);
                }
            })
        }
    }, [props.show, dataManager])

    const onFieldChange = (name, value) => {
        if (cardRef.current) {
            cardRef.current.onFieldChange(name, value);
        }
    }

    const onPaymentSourceSelected = (source) => {
        setSelectedPaymentSource(source);
    }

    const onSubmit = async (data, callback) => {
        if (cardRef.current) {
            setError(false);
            const result = await cardRef.current.getPaymentMethod(data);
            if (!result) {
                setError("Card information is incomplete or incorrect. Please update the card details.");
                console.log("Handle error in getting payment method");
                return
            } else if (result.errors) {
                if (props.setErrorFields) {
                    props.setErrorFields(result.errors);
                }
                return
            }

            let dm = null;
            const isInv = _.isNil(props.agreement);
            if (isInv) {
                dm = new InvoiceDataManager(props.invoice);
            } else {
                dm = new AgreementDataManager(props.agreement);
            }
            const paymentData = {
                paymentProcessorData: {
                    sportsPay: {
                        ...result,
                    }
                },
                amount: data.amount,
                paymentProcessor: "sportspay",
                invoiceFee: data.feeAmount
            }

            const payUrl = isInv ? `/invoices/${dm.getUUID()}/online_pay` : `/agreements/${dm.getUUID()}/online_pay`;
            serverPost(getApiUrl(payUrl), paymentData).then((res) => {
                if (res) {
                    setTimeout(() => {
                        Notification.Show("Payment Successful");
                        callback(res);
                    }, 100)
                } else {
                    setError("Payment failed. Please update the card details");
                }
            })
        }
    }

    return (
        <>
            <SportsPayCardInput
                ref={cardRef} apiKey={paymentIntent && paymentIntent.secret}
                host={paymentIntent && paymentIntent.host}
                setInitialFields={props.setInitialFields}
            />
            {
                error &&
                    <span className="error">{ error }</span>
            }
        </>
    )
})

export default InvoiceAgreementPaymentModal;
