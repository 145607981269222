import $ from "jquery";
import 'jquery-ui/ui/widgets/datepicker';
import { useEffect, useState, useRef, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import classnames from 'classnames';
import BaseContainer from '../../components/Container';
import BaseOverlayTrigger from '../../components/BaseOverlayTrigger';
import ConfirmationButton from '../../components/ConfirmationButton';
import DeleteButton from '../../components/DeleteButton';
import Notification from '../../components/Notification';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import SingleInvoiceEmailModal from '../../components/modals/SingleInvoiceEmailModal';
import MassInvoiceEmailModal from '../../components/modals/MassInvoiceEmailModal';
import InvoicesCSVExportModal from '../../components/modals/InvoicesCSVExportModal';
import InvoicesXeroExportModal from '../../components/modals/InvoicesXeroExportModal';
import { getUrl, serverFetch, serverPost } from '../../helpers/server';
import { hasAccess, BaseContext, currencyFormat } from '../../helpers/common';
import { getLimitOptions, dateRangeClassCheck, downloadBlob, getDateFormatForFacility } from '../../helpers/common';
import { getCompanyInfo } from '../../helpers/storage';
import { useParams, useSearchParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Table, InputGroup, Form, Button, Row, ButtonToolbar, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from "react-router-dom";
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import InvoicesLineItemExportModal from "../../components/modals/InvoicesLineItemExportModal";
const _ = require("lodash");

function List() {
    const { userInfo, getApiUrl, getFacilityName } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('common');

    const [showInvoiceEmailModal, setShowInvoiceEmailModal] = useState(false);
    const [showMassInvoiceEmailModal, setShowMassInvoiceEmailModal] = useState(false);
    const [showInvoicesCSVExportModal, setShowInvoicesCSVExportModal] = useState(false);
    const [showInvoicesXeroExportModal, setShowInvoicesXeroExportModal] = useState(false);
    const [showInvoicesLineItemExportModal, setShowInvoicesLineItemExportModal] = useState(false);

    const [initialSearch, setInitialSearch] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [settings, setSettings] = useState(getCompanyInfo());
    const [ searchParams ] = useSearchParams();
    let groupId = searchParams.get('groupId');

    const [searchAllQuery, setSearchAllQuery] = useState("");
    const [query, setQuery] = useState("");
    const [paymentStartDate, setPaymentStartDate] = useState(null);
    const [paymentEndDate, setPaymentEndDate] = useState(null);
    const [invNumFilter, setInvNumFilter] = useState(null);
    const [accountingCode, setAccountingCode] = useState(null);
    const [editFilter, setEditFilter] = useState("active");
    const [paidFilter, setPaidFilter] = useState(null);
    const [sentFilter, setSentFilter] = useState(null);
    const [dueDateFilter, setDueDateFilter] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [venueFilter, setVenueFilter] = useState(null);
    const [sortFilter, setSortFilter] = useState("idDesc");
    const [limit, setLimit] = useState(20);

    const [userGroup, setUserGroup] = useState({});
    const [invoices, setInvoices] = useState([]);
    const [venues, setVenues] = useState([]);
    const [loadingVenues, setLoadingVenues] = useState(true);
    const [checkedItemIds, setCheckedItemIds] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);

    const [accountingCodeOptions, setAccountingCodeOptions] = useState([]);
    
    const paymentStartDateRef = useRef();
    paymentStartDateRef.current = paymentStartDate;
    const paymentEndDateRef = useRef();
    paymentEndDateRef.current = paymentEndDate;
    const startDateRef = useRef();
    startDateRef.current = startDate;
    const endDateRef = useRef();
    endDateRef.current = endDate;
    const queryTimer = useRef(null);

    useEffect(() => {
        document.title = "Invoices - " + getFacilityName();
    }, []);

    useEffect(() => {
        if (hasAccess("settings", userInfo, "view")) {
            serverFetch(getApiUrl('/accounts')).then((res) => {
                const accountingCodeMap = getAccountingCodeMap(res);

                setAccountingCodeOptions(_.flatMap(accountingCodeMap, parentCode => {
                    var codes = _.map(parentCode.subCodes, code => {
                        return {"id": code.id, "label": code.parentAccountName + " -> " + code.account}
                    })

                    codes = [{"id": parentCode.id, "label": parentCode.account}, ...codes];

                    return codes;
                }))
            })
        }
    }, []);

    const getAccountingCodeMap = (codes) => {
        const accountingCodesMap = {}

        _.each(codes, code => {
            if (!code.parentAccountId) {
                accountingCodesMap[code.id] = code;
                accountingCodesMap[code.id].subCodes = [];
            }
        })

        _.each(codes, code => {
            if (code.parentAccountId != null) {
                accountingCodesMap[code.parentAccountId].subCodes.push(code);
            }
        })

        return accountingCodesMap;
    }

    useEffect(() => {
        if (groupId) {
            serverFetch(getApiUrl(`/user_groups/${groupId}`)).then((res) => {
                setUserGroup(res);
            })
        }
    }, [groupId]);

    useEffect(() => {
        groupId = searchParams.get('groupId')
        onSearch(true);
    }, [searchParams])

    useEffect(() => {
        // Load the options from cache
        const optionsString = localStorage.getItem(facilityLink + "_filter_cache_invoices");
        if (optionsString && !groupId) {
            const options = JSON.parse(optionsString);
            if (options.dateFilter) {
                setDateFilter(options.dateFilter.filterType);
                setStartDate(options.dateFilter.startDate);
                setEndDate(options.dateFilter.endDate);
            }
            if (options.paidFilter) {
                setPaidFilter(options.paidFilter)
            }
            if (options.dueDateFilter) {
                setDueDateFilter(options.dueDateFilter)
            }
            if (options.sentFilter) {
                setSentFilter(options.sentFilter)
            }
            if (options.venueFilter) {
                setVenueFilter(options.venueFilter)
            }
            if (options.activeFilter) {
                setEditFilter(options.activeFilter);
            }
            if (options.sort) {
                setSortFilter(options.sort);
            }
            if (options.paymentStartDate) {
                setPaymentStartDate(options.paymentStartDate)
            }
            if (options.paymentEndDate) {
                setPaymentEndDate(options.paymentEndDate)
            }
            if (options.invoiceNumberFilter) {
                setInvNumFilter(options.invoiceNumberFilter)
            }
        }
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl('')).then((res) => {
            setSettings(res);
        })

        serverFetch(getApiUrl('/venues')).then((res) => {
            setVenues(res);
            setInitialSearch(true);
            setLoadingVenues(false);
        })
    }, [facilityLink]);

    useEffect(() => {
        onSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editFilter, invNumFilter, dueDateFilter, sentFilter, paidFilter, dateFilter,
        venueFilter, limit, query, paymentStartDate, paymentEndDate, loadingVenues, sortFilter, accountingCode]);

    useEffect(() => {
        clearTimeout(queryTimer.current);
        queryTimer.current = setTimeout((callback, kk, vv) => {
                if (callback) {
                    callback(kk, vv);
                }
                queryTimer.current = null;
            },
            400,
            onSearch,
        );
    }, [searchAllQuery]);

    useEffect(() => {
        setTimeout(() => {
            $('#dateStart').datepicker( "destroy" );
            $('#dateStart').datepicker({
                defaultDate: 0,
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setStartDate(value);
                    setTimeout(() => $('#dateEnd').datepicker( "show" ), 100);
                }
            });
            $('#dateEnd').datepicker( "destroy" );
            $('#dateEnd').datepicker({
                defaultDate: "+1m",
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setEndDate(value)
                }
            });
        }, 100);
        setTimeout(() => {
            $('#paymentStartDate').datepicker( "destroy" );
            $('#paymentStartDate').datepicker({
                defaultDate: 0,
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, paymentStartDateRef.current, paymentEndDateRef.current),
                onSelect: (value) => {
                    setPaymentStartDate(value);
                    setTimeout(() => $('#paymentEndDate').datepicker( "show" ), 100);
                }
            });
            $('#paymentEndDate').datepicker( "destroy" );
            $('#paymentEndDate').datepicker({
                defaultDate: "+1d",
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, paymentStartDateRef.current, paymentEndDateRef.current),
                onSelect: (value) => {
                    setPaymentEndDate(value)
                }
            });
        }, 100);
    }, [dateFilter, startDate, endDate]);

    useEffect(() => {
        setCheckedItems(_.filter(invoices, (b) => checkedItemIds.includes(getRowId(b))));
    }, [checkedItemIds]);

    const dateFilterChanged = (value) => {
        if (value !== "all" && sortFilter === "idDesc") {
            setSortFilter("invDateAsc");
        }
        setDateFilter(value);
    }

    const onPrint = () => {
        if (checkedItemIds.length === 0) {
            Notification.Show(t('invoice.list.error_choose_one'));
        } else {
            _.each(checkedItems, async (invoice) => {
                const url = getApiUrl(`/invoices/${invoice.uuid}/pdf`);
                serverPost(url, {}, { noJson: true }).then((res) => {
                    downloadBlob(res, `invoice-${invoice.invoiceNumber}`);
                });
            })
        }
    }

    const onEmail = () => {
        const cancelledItems = _.filter(checkedItems, (i) => i.isCancel);
        if (checkedItems.length === 0) {
            Notification.Show(t('invoice.list.error_choose_one'));
        } else if (cancelledItems.length > 0) {
            Notification.Show(t('invoice.list.error.cant_email_cancelled'));
        } else if (checkedItems.length === 1) {
            setShowInvoiceEmailModal(true);
        } else {
            setShowMassInvoiceEmailModal(true);
        }
    }

    const onReportSelected = (reportType) => {
        console.log("Report selected " + reportType);
        if (checkedItems.length === 0) {
            Notification.Show(t('invoice.list.error_choose_one'));
        } else {
            if (reportType === "csv") {
                setShowInvoicesCSVExportModal(true);
            } else if (reportType === "xero") {
                setShowInvoicesXeroExportModal(true);
            } else if (reportType === "line-items") {
                setShowInvoicesLineItemExportModal(true);
            }
        }
    }

    const onDelete = () => {
        console.log("Delete clicked ");
        if (checkedItemIds.length === 0) {
            Notification.Show(t('invoice.list.error_choose_one'));
        } else {
            serverPost(getApiUrl(`/invoices/batch_cancel`), {
                ids: checkedItemIds
            }).then((res)=> {
                if (res) {
                    Notification.Show(t('invoice.list.successfully_cancelled'));
                }
                onSearch();
            })
        }
    }

    const onKeyPress = (event) => {
        if (event.key === "Enter") {
            onSearch();
        }
    }

    const onEdit = (invoice) => {
        navigate(`/${facilityLink}/invoice/update?uuid=${invoice.uuid}`);
    }

    const cancelInvoice = (invoice) => {
        serverPost(getApiUrl(`/invoices/${invoice.uuid}/cancel`)).then((res) => {
            if (res) {
                Notification.Show(t('invoice.list.successfully_cancelled'));
            }
            onSearch();
        });
    }

    const onClearPaymentDates = () => {
        setPaymentStartDate(null);
        setPaymentEndDate(null);
        $('#paymentStartDate').datepicker('setDate', null);
        $('#paymentEndDate').datepicker('setDate', null);
    }

    const getQueryParams = () => {
        const queryParams = {
            groupId: groupId,
            dateFilter: dateFilter && {
                filterType: dateFilter,
                startDate: startDate,
                endDate: endDate
            },
            dueDateFilter: dueDateFilter,
            paidFilter: paidFilter,
            sentFilter: sentFilter,
            venueFilter: venueFilter,
            searchAllQuery: searchAllQuery,
            paymentStartDate: paymentStartDate,
            paymentEndDate: paymentEndDate,
            activeFilter: editFilter,
            groupName: query,
            invoiceNumberFilter: invNumFilter,
            sort: sortFilter,
            accountingCodeFilter: accountingCode,
        }
        if (!_.isNil(queryParams.dueDateFilter) && _.isNil(queryParams.paidFilter)) {
            queryParams.paidFilter = "partlyUnpaid";
        }
        return queryParams;
    }

    const onSearch = (restart = true) => {
        if (loadingVenues) {
            return;
        }
        if (dateFilter === "dateRange" && (_.isNil(startDate) || _.isNil(endDate))) {
            return;
        }
        if (!groupId) {
            // Cache the options
            localStorage.setItem(facilityLink + "_filter_cache_invoices", JSON.stringify(getQueryParams()));
        }

        const searchUrl = getApiUrl('/invoices/search');
        const data = {
            pagination: {
                from: restart ? 0 : (invoices.length || 0),
                limit: limit
            },
            query: getQueryParams(),
        };
        if (restart) {
            setInvoices([]);
        }
        serverPost(searchUrl, data).then((res) => {
            setHasMore(res.length >= limit);
            if (restart) {
                res = setAccountingCodeString(res);

                setInvoices(res);
            } else {
                res = setAccountingCodeString(res);

                setInvoices(_.concat(invoices, res))
            }
        })
    }

    const setAccountingCodeString = (invoices) => {
        _.each(invoices, inv => {
            var accountingCodes = [];

            _.each(inv.lineItems, lineItem => {
                if (lineItem.accountingCode != null) {
                    accountingCodes.push(lineItem.accountingCode);
                }
            })

            accountingCodes = [...new Set(accountingCodes)];
            
            inv.accountingCodesString = _.join(accountingCodes, ", ");
        })

        return invoices;
    }

    const updateChecked = (event) => {
        if (event.target.value === "checkall") {
            let newCheckedItems = [];
            if (event.target.checked) {
                newCheckedItems = _.map(invoices, (b, i) => getRowId(b));
                newCheckedItems.push("checkall");
            }
            setCheckedItemIds(newCheckedItems);
        } else {
            const newCheckedItems = [...checkedItemIds];
            if (event.target.checked) {
                newCheckedItems.push(event.target.value);
            } else {
                let index = newCheckedItems.indexOf(event.target.value);
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
                index = newCheckedItems.indexOf("checkall");
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
            }
            setCheckedItemIds(newCheckedItems);
        }
    }

    function getRowId(invoice) {
        return ("" + invoice.id);
    }

    const editFilterOptions = [
        { 'id': "active", 'label': t("common.active") },
        { 'id': "cancelled", 'label': t("common.cancelled") },
    ];

    const invNumFilterOptions = [
        { 'id': "amended", 'label': t("invoice.list.amended") },
        { 'id': "amendedToday", 'label': t("invoice.list.amended_today") },
        { 'id': "amended7", 'label': t("invoice.list.amended_7") },
        { 'id': "amended30", 'label': t("invoice.list.amended_30") },
        { 'id': "amended90", 'label': t("invoice.list.amended_90") },
    ];

    const paidFilterOptions = [
        { 'id': "fullyPaid", 'label': t("invoice.list.fully_paid") },
        { 'id': "fullyPartly", 'label': t("invoice.list.fully_partly_paid") },
        { 'id': "partlyPaid", 'label': t("invoice.list.partly_paid") },
        { 'id': "partlyUnpaid", 'label': t("invoice.list.partly_unpaid") },
        { 'id': "notPaid", 'label': t("invoice.list.unpaid") },
    ];

    const dueDateFilterOptions = [
        { 'id': "overdue", 'label': t("invoice.list.overdue") },
        { 'id': "overdue30", 'label': t("invoice.list.overdue_30") },
        { 'id': "overdue60", 'label': t("invoice.list.overdue_60") },
        { 'id': "overdue90", 'label': t("invoice.list.overdue_90") },
    ];

    const dateFilterOptions = [
        { 'id': "today", 'label': t("common.today") },
        { 'id': "yesterday", 'label': t("common.yesterday") },
        { 'id': "thisWeek", 'label': t("common.this_week") },
        { 'id': "lastWeek", 'label': t("common.last_week") },
        { 'id': "thisMonth", 'label': t("common.this_month") },
        { 'id': "lastMonth", 'label': t("common.last_month") },
        { 'id': "dateRange", 'label': t("common.date_range") },
    ];

    const sentFilterOptions = [
        { 'id': "sent", 'label': t("common.yes") },
        { 'id': "unsent", 'label': t("common.no") },
    ]

    const venueOptions = _.map(venues, (venue, i) => { return { 'value': venue.id, 'label': venue.name } });

    const reportOptions = [
        { 'id': "csv", 'label': t("csv_export.title"), 'icon': 'fa-table' },
        { 'id': "line-items", 'label': "Invoice Line Items Export", 'icon': 'fa-table' },
        { 'id': "xero", 'label': t("invoice.list.xero_export"), 'icon': 'fa-table' },
    ];

    const sortOptions = [
        { 'id': "idDesc", 'label': t("common.id_desc") },
        { 'id': "invDateAsc", 'label': t("invoice.list.inv_date_asc") },
        { 'id': "invDateDesc", 'label': t("invoice.list.inv_date_desc") },
        { 'id': "amountAsc", 'label': t("invoice.list.amount_asc") },
        { 'id': "amountDesc", 'label': t("invoice.list.amount_desc") },
    ]

    const dateFormat = getDateFormatForFacility(settings);
    const tabsParams = {
        searchParams: groupId && `groupId=${groupId}`,
        groupId: groupId,
        userGroup: userGroup,
        userInfo: userInfo,
        settings: settings
    };

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "invoices", tabsParams)} />
            <div className="content-box">
                <div className="content-body">
                    <div className="d-flex flex-row gap-12 flex-wrap">
                        <div className="flex-grow-0 d-flex flex-row">
                            <InputGroup>
                                <Form.Control type="text" className="inline" name="searchAllQuery" value={searchAllQuery} onChange={(event) => { setSearchAllQuery(event.target.value) }} onKeyPress={onKeyPress}/>
                                <InputGroup.Text className="skinny" onClick={() => onSearch()}><i className="fa fa-search"/></InputGroup.Text>
                            </InputGroup>
                        </div>
                        <div className="flex-grow-0 d-flex flex-row gap-6 flex-wrap">
                            <BaseOverlayTrigger content={"Payment Date Range"} >
                                <InputGroup style={{ width: "100px" }}>
                                    <Form.Control type="text" className="inline" name="paymentStartDate" id="paymentStartDate" />
                                    <InputGroup.Text className="skinny">{t("common.to")}</InputGroup.Text>
                                    <Form.Control type="text" className="inline" name="paymentEndDate" id="paymentEndDate" />
                                    <InputGroup.Text className="skinny" onClick={() => onClearPaymentDates()}><i className="fa fa-x"/></InputGroup.Text>
                                </InputGroup>
                            </BaseOverlayTrigger>
                            <div>
                                <SingleSelectDropdown className="inline" items={venueOptions} selectedId={venueFilter} onSelect={setVenueFilter} idField={"value"} showAll />
                            </div>
                        </div>
                        <div className="flex-grow-1 d-flex flex-row justify-content-end">
                            <div className="float-end d-flex align-items-center flex-wrap">
                                <SingleSelectDropdown className="inline" items={getLimitOptions()} selectedId={limit} onSelect={setLimit} menuOnly/>
                                <Button variant="outline-primary" onClick={onPrint}><i className="fa fa-print"/> {t("common.print")}</Button>
                                <Button variant="outline-primary" onClick={onEmail}><i className="fa fa-envelope"/> {t("common.email")}</Button>
                                <SingleSelectDropdown className="inline" toggleLabel={<span><i className="fa fa-small fa-circle-plus"/> {t("common.new_report")}</span>} items={reportOptions} onSelect={onReportSelected} align="end" menuOnly/>
                                {
                                    hasAccess("invoices", userInfo, "update") &&
                                        <DeleteButton size="md" confirmationText={checkedItemIds.length>2 && "CANCEL INVOICES"}
                                            warningText={checkedItemIds.length>2 && "You are trying to cancel a lot of invoices? Are you sure?"}
                                            title="Cancel Invoice(s)?"
                                            body="Are you sure you want to cancel the invoice(s)?"
                                            onDelete={onDelete}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <Col md="9" className="text-end d-grid align-items-center">
                            <span><strong>{t("common.sort_order")}:</strong></span>
                        </Col>
                        <Col md="3">
                            <SingleSelectDropdown name="sort" selectedId={sortFilter} items={sortOptions} onSelect={setSortFilter} />
                        </Col>
                    </Row>
                    <InfiniteScroll
                        dataLength={invoices.length}
                        next={() => onSearch(false)}
                        hasMore={hasMore}
                        scrollableTarget="content_wrapper"
                    >
                        <Table hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{t('invoice.list.invoice_number')}</th>
                                    <th>{t('common.group_name')}</th>
                                    {hasAccess("settings", userInfo, "view") && settings.useAccountingCodesOnInvoice && <th>{t('common.accounting_code')}</th>}
                                    <th>{t('common.date')}</th>
                                    <th>{t('invoice.list.due_date')}</th>
                                    <th>{t('common.amount')}</th>
                                    <th>{t('common.paid')}</th>
                                    <th>{t('common.sent')}</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <th className="controls no-stretch">
                                        <div className="checkbox check-success inline">
                                            <input type="checkbox" className="" value="checkall" id="checkall" name="delete[]" checked={checkedItemIds.includes("checkall")} onChange={ (event) => updateChecked(event) }/>
                                            <label htmlFor="checkall"/>
                                        </div>
                                    </th>
                                    <th className="controls"><SingleSelectDropdown items={invNumFilterOptions} selectedId={invNumFilter} onSelect={setInvNumFilter} align={"end"} showAll /></th>
                                    <th className="controls"><Form.Control type="text" name="query" value={query} onChange={(event) => { setQuery(event.target.value) }}/></th>
                                    {hasAccess("settings", userInfo, "view") && settings.useAccountingCodesOnInvoice && <th className="controls"><SingleSelectDropdown items={accountingCodeOptions} selectedId={accountingCode} onSelect={setAccountingCode} align={"end"} showAll showSearch /></th>}
                                    <th className="controls">
                                        <SingleSelectDropdown items={dateFilterOptions} selectedId={dateFilter} onSelect={dateFilterChanged} showAll />
                                        {
                                            dateFilter === "dateRange" &&
                                                <BaseForm initialFormFields={{ dateStart: startDate, dateEnd: endDate }}>
                                                    <BaseForm.Control type="text" name="dateStart" id={"dateStart"} />
                                                    <BaseForm.Control type="text" name="dateEnd" id={"dateEnd"} />
                                                    <Button variant="outline-primary" onClick={(event) => onSearch()}><i className="fa fa-refresh fa-small"/></Button>
                                                </BaseForm>
                                        }
                                    </th>
                                    <th className="controls"><SingleSelectDropdown items={dueDateFilterOptions} selectedId={dueDateFilter} onSelect={setDueDateFilter} showAll /></th>
                                    <th className="controls"></th>
                                    <th className="controls text-center"><SingleSelectDropdown items={paidFilterOptions} selectedId={paidFilter} onSelect={setPaidFilter} align={"end"} akey="test" showAll /></th>
                                    <th className="controls"><SingleSelectDropdown items={sentFilterOptions} selectedId={sentFilter} onSelect={setSentFilter} align={"end"} showAll /></th>
                                    <th className="controls"><SingleSelectDropdown items={editFilterOptions} selectedId={editFilter} onSelect={setEditFilter} align={"end"} showAll /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _.map(invoices, (invoice, i) =>
                                        <tr key={i} className={classnames(invoice.isCancel && "de-highlighted")}>
                                            <td className="no-stretch">
                                                <div className="checkbox check-success inline">
                                                    <input type="checkbox" className="" value={getRowId(invoice)} id={getRowId(invoice)} name="delete[]"  checked={checkedItemIds.includes(getRowId(invoice))} onChange={ (event) => updateChecked(event) }/>
                                                    <label htmlFor={getRowId(invoice)}/>
                                                </div>
                                            </td>
                                            <td>
                                                <Link to={`/${facilityLink}/invoice/view?uuid=${invoice.uuid}`}>
                                                    { invoice.invoiceNumber }
                                                </Link>
                                                {
                                                    invoice.hasChanged && false &&
                                                        <>&nbsp;<i className="fa fa-edit gray"/></>
                                                }
                                            </td>
                                            <td>
                                                <Link to={`/${facilityLink}/invoice/list?groupId=${invoice.group.id}`}>
                                                    { invoice.group.name }
                                                    {
                                                        invoice.invoiceLabel &&
                                                            <>&nbsp;<span className="label label-info"> { invoice.invoiceLabel }</span></>
                                                    }
                                                </Link>
                                            </td>
                                            {hasAccess("settings", userInfo, "view") && settings.useAccountingCodesOnInvoice && <td>{invoice.accountingCodesString}</td>}
                                            <td>{ moment(invoice.invoiceDate).format(dateFormat) }</td>
                                            <td className={classnames(moment(invoice.dueDate).isBefore(moment(), 'date') && "overdue")}>{ moment(invoice.dueDate).format(dateFormat) }</td>
                                            <td className="text-end"><span className="label">{ currencyFormat(invoice.total) }</span></td>
                                            <td className="text-end">
                                                <span className={classnames("label", invoice.amountDue === 0 && "paid", (invoice.amountDue > 0 && invoice.paidAmount > 0) && "partlypaid")}>
                                                    { currencyFormat(invoice.paidAmount) }
                                                </span>
                                            </td>
                                            <td className="text-center">
                                                { invoice.isSent ? <i className="fa fa-check-circle fa-c-success fs-16"></i> : <span className="label">No</span> }
                                            </td>
                                            <td className="text-center">
                                            {
                                                hasAccess("invoices", userInfo, "update") &&
                                                    <ButtonToolbar>
                                                        <Link to={`/${facilityLink}/invoice/update?uuid=${invoice.uuid}`}><Button variant="primary"><i className="fa fa-edit"/></Button></Link>
                                                        <ConfirmationButton variant="outline-primary" title="Cancel Invoice?" body="Are you sure you want to cancel the invoice?" onConfirm={() => cancelInvoice(invoice)}><i className="fa fa-x"/></ConfirmationButton>
                                                    </ButtonToolbar>
                                            }
                                            </td>
                                        </tr>
                                    )
                                }
                                {
                                    hasMore ?
                                        <tr>
                                            <td colSpan={11} className="center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                    : <tr>
                                            <td colSpan={11} className="center">
                                                <div className="label">{t('invoice.list.end_of_invoices')}</div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </InfiniteScroll>
                </div>
            </div>
            <SingleInvoiceEmailModal show={showInvoiceEmailModal} onClose={setShowInvoiceEmailModal} invoice={checkedItems[0]} />
            <MassInvoiceEmailModal show={showMassInvoiceEmailModal} onClose={setShowMassInvoiceEmailModal} invoices={checkedItems} />
            <InvoicesCSVExportModal
                show={showInvoicesCSVExportModal}
                onClose={setShowInvoicesCSVExportModal}
                items={checkedItems}
                criteria={getQueryParams()}
                useCriteria={checkedItemIds.includes("checkall")} />
            <InvoicesLineItemExportModal
                show={showInvoicesLineItemExportModal}
                onClose={setShowInvoicesLineItemExportModal}
                items={checkedItems}
                criteria={getQueryParams()}
                useCriteria={checkedItemIds.includes("checkall")} />
            <InvoicesXeroExportModal
                show={showInvoicesXeroExportModal}
                onClose={setShowInvoicesXeroExportModal}
                invoices={checkedItems}
                criteria={getQueryParams()}
                filenamePrefix="Invoices-Report"
                useCriteria={checkedItemIds.includes("checkall")} />
        </BaseContainer>
    );
}

export default List;
