import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Loader from '../../components/Loader';
import BaseContainer from '../../components/Container';
import DeleteButton from '../../components/DeleteButton';
import AddOrEditAccountModal from '../../components/modals/AddOrEditAccountModal';
import TabHeader from '../../components/TabHeader';
import { serverFetch, serverDelete } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { Table, Button, Row, Col, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Notification from '../../components/Notification';
import { Link } from 'react-router-dom';
const _ = require("lodash");

function AccountList() {
    const { getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [parentAccountOptions, setParentAccountOptions] = useState([]);
    const [customerTypeToEdit, setCustomerTypeToEdit] = useState(null);
    const [showAddCustomerTypeModal, setShowAddCustomerTypeModal] = useState(false);

    useEffect(() => {
        document.title = "Accounts - " + getFacilityName();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const onModalClose = () => {
        fetchData(true);
        setShowAddCustomerTypeModal(false);
    }

    const fetchData = (skipCache = false) => {
        setLoading(true);
        serverFetch(getApiUrl('/accounts'), { skipCache }).then((res) => {
            const accountingCodeMap = getAccountingCodeMap(res);

            setAccounts(_.flatMap(accountingCodeMap, parentCode => {
                var codes = _.map(parentCode.subCodes, code => {
                    return code
                })

                codes = [parentCode, ...codes];

                return codes;
            }))
            setLoading(false);
        })
    }

    const getAccountingCodeMap = (codes) => {
        const accountingCodesMap = {}

        _.each(codes, code => {
            if (!code.parentAccountId) {
                accountingCodesMap[code.id] = code;
                accountingCodesMap[code.id].subCodes = [];
            }
        })

        _.each(codes, code => {
            if (code.parentAccountId != null) {
                accountingCodesMap[code.parentAccountId].subCodes.push(code);
            }
        })

        return accountingCodesMap;
    }

    const addAccount = () => {
        setCustomerTypeToEdit(null);
        setParentAccountOptions(getParentAccountOptions());
        console.log(parentAccountOptions);
        setShowAddCustomerTypeModal(true);
    }

    const editCustomerType = (item) => {
        setCustomerTypeToEdit(item);
        setParentAccountOptions(getParentAccountOptions());
        setShowAddCustomerTypeModal(true);
    }

    const deleteAccount = (item) => { // TODO: check that there are no subcodes before deleting
        const deleteAccountUrl = getApiUrl(`/accounts/${item.id}`);

        const codeMap = getAccountingCodeMap(accounts);

        if (codeMap[item.id] === undefined || codeMap[item.id].subCodes.length === 0) {
            serverDelete(deleteAccountUrl, {}).then((res) => {
                fetchData(true);
            })
        } else {
            Notification.Show("This account has child accounts. Please delete the children before deleting this account.");
            return;
        }
    }

    const getParentAccountOptions = () => {
        const parentOptions = _.filter(accounts, account => {
            return account.parentAccountId === null;
        });

        var parentOptionsFormatted = _.map(parentOptions, account => {
            return {"id": account.id, "label": account.account}
        });
        parentOptionsFormatted.unshift({"id": null, "label": "None"});
        return parentOptionsFormatted;
    }

    console.log(accounts);

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "accounts")}/>
            <div className="content-box">
                <div className="content-body">
                    <Alert variant="info">
                        <strong>Account Numbers:</strong> Use this page to add system wide account numbers. These numbers can be used to track different booking times and additional products as items get paid.
                    </Alert>
                    <Button variant="outline-primary" onClick={() => addAccount()}><i className="fa fa-plus-circle"></i> {t('setting.accounts.add')}</Button>
                    <Link to={"/" + facilityLink + "/setting/accountlist/import"}>
                        <Button variant="outline-primary"><i className="fa fa-plus-circle"></i> {t('setting.accounts.batch_add')}</Button>
                    </Link>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <Col md="6">
                            <Loader loading={loading}>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>{t('setting.accounts.account')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.map(accounts, (item, i) =>
                                                <tr key={i}>
                                                    <td style={{"white-space": "pre"}}><Button variant="alink" onClick={() => editCustomerType(item)}>{item.parentAccountId ? "     " + item.parentAccountName + " -> " + item.account : item.account}</Button></td>
                                                    <td><DeleteButton onDelete={() => deleteAccount(item)}></DeleteButton></td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Loader>
                        </Col>
                    </Row>
                </div>
            </div>
            <AddOrEditAccountModal show={showAddCustomerTypeModal} onClose={onModalClose} itemToEdit={customerTypeToEdit} options={parentAccountOptions}/>
        </BaseContainer>
    );
}

export default AccountList;
