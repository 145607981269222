import {hasAccess, isFullAdmin, isNotAdmin} from './common';
const _ = require("lodash");

function getSearchParams(options, append=false) {
    if (_.isNil(options)) {
        return "";
    }
    let prefix = "?";
    if (append) {
        prefix = "&";
    }
    return options.searchParams ? (`${prefix}${options.searchParams}` || "") : "";
}

function getClassSettingItems(t, facilityLink) {
    /*
    {
        'label': t('tabs.setting_classes.class_templates'),
        'link': '/' + facilityLink + '/setting/classes',
    },
    */
    return [{
        'label': t('tabs.setting_classes.class_settings'),
        'link': '/' + facilityLink + '/setting/classes',
    }, {
        'label': t('tabs.setting_classes.forms'),
        'link': '/' + facilityLink + '/setting/classes-forms',
        'matchPathnameOnly': true
    }];
}

function getSuperAdminItems(t, facilityLink) {
    return [{
        'label': 'Facilities',
        'link': '/' + facilityLink + '/facility/list',
    }, {
        'label': 'Spoof',
        'link': '/' + facilityLink + '/facility/spoof',
    }];
}

function getCustomerTypeItems(t, facilityLink) {
    return [{
        'label': t('tabs.customer_types.customer_types'),
        'link': '/' + facilityLink + '/setting/customertypelist',
    }];
}

function getAddOnItems(t, facilityLink) {
    return [{
        'label': t('tabs.addons.addon'),
        'link': '/' + facilityLink + '/setting/addonlist',
    }];
}

function getLockerRoomItems(t, facilityLink) {
    return [{
        'label': t('tabs.locker_rooms.assign_lockers'),
        'link': '/' + facilityLink + '/setting/lockers',
        'matchPathnameOnly': true
    }, {
        'label': t('tabs.locker_rooms.locker_rooms'),
        'link': '/' + facilityLink + '/setting/lockerroom',
    }];
}

function getSettingItems(t, facilityLink) {
    return [{
        'label': t('tabs.setting.setting'),
        'link': '/' + facilityLink + '/setting/setting',
    }];
}

function getAdminSettingItems(t, facilityLink, options = null) {
    let items = [{
        'label': t('tabs.admins.admins'),
        'link': '/' + facilityLink + '/setting/adminlist',
    },];

    if (!options || !options.partner) {
        items.push({
            'label': t('tabs.admins.reply_to'),
            'link': '/' + facilityLink + '/setting/replytolist',
        });
        items.push({
            'label': t('tabs.admins.notification'),
            'link': '/' + facilityLink + '/setting/notification',
        });
        const allowedFacilities = ["membertousportandwellness", "bmo", "coleharbourplace", "centennialarena"];
        if (_.includes(allowedFacilities, facilityLink)) {
            items.push({
                'label': t('tabs.admins.api_tokens'),
                'link': '/' + facilityLink + '/setting/api_tokens',
            });
        }
    }

    return items;
}

function getBookingSearchItems(t, facilityLink) {
    return [{
        'label': t('tabs.booking.search'),
        'link': '/' + facilityLink + '/booking/search',
    }];
}

function getBookingsItems(t, facilityLink,  options) {
    const items = [{
        'label': t('tabs.booking.list', options),
        'link': '/' + facilityLink + '/booking/list' + getSearchParams(options),
        'icon': 'fa-book'
    }];
    let extraItems = [];
    if (options.groupId) {
        extraItems = [{
            'label': t('tabs.invoice.list', options),
            'link': '/' + facilityLink + '/invoice/list' + getSearchParams(options),
            'icon': 'fa-file'
        }, {
            'label': t('tabs.agreement.list'),
            'link': '/' + facilityLink + '/agreement/list' + getSearchParams(options),
            'icon': 'fa-square-pen'
        }, {
            'label': t('tabs.class.list'),
            'link': '/' + facilityLink + '/class/list' + getSearchParams(options),
            'icon': 'fa-users'
        }, {
            'label': t('tabs.payment.list'),
            'link': '/' + facilityLink + '/booking/paymentlist' + getSearchParams(options),
            'icon': 'fa-money'
        }, {
            'label': t('tabs.credit.list'),
            'link': '/' + facilityLink + '/credit/list' + getSearchParams(options),
            'icon': 'fa-credit-card'
        }, {
            'label': t('tabs.customer.profile'),
            'link': '/' + facilityLink + '/user/profile' + getSearchParams(options),
            'icon': 'fa-user'
        }, {
            'label': t('tabs.customer.notes'),
            'link': '/' + facilityLink + '/user/comments' + getSearchParams(options),
            'icon': 'fa-comment'
        }]
    } else  if (options && options.userInfo && (options.userInfo.isSuperAdmin || isFullAdmin(options.userInfo)) && options.settings && options.settings.isPartnerFacility) {
        extraItems = [{
            'label': "LeagueApps Sync",
            'link': '/' + facilityLink + '/booking/partner_list' + getSearchParams(options),
            'icon': 'fa-sync',
        }]
    }

    return _.concat(items, extraItems);
}

function getUserItems(t, facilityLink,  options) {
    return [{
        'label': t('tabs.booking.user'),
        'link': '/' + facilityLink + '/booking/list' + getSearchParams(options),
        'icon': 'fa-book'
    }, {
        'label': t('tabs.invoice.list'),
        'link': '/' + facilityLink + '/invoice/list' + getSearchParams(options),
        'icon': 'fa-file'
    }, {
        'label': t('tabs.agreement.list'),
        'link': '/' + facilityLink + '/agreement/list' + getSearchParams(options),
        'icon': 'fa-square-pen'
    }, options.settings && options.settings.allowClasses && {
        'label': t('tabs.class.list'),
        'link': '/' + facilityLink + '/class/list' + getSearchParams(options),
        'icon': 'fa-users'
    }];
}

function getUserGroupItems(t, facilityLink,  options) {
    let items = [hasAccess("booking", options.userInfo, "view") && {
        'label': t('tabs.booking.user_group', options),
        'link': '/' + facilityLink + '/booking/list' + getSearchParams(options),
        'icon': 'fa-book'
    }, hasAccess("invoices", options.userInfo, "view") && {
        'label': t('tabs.invoice.list', options),
        'link': '/' + facilityLink + '/invoice/list' + getSearchParams(options),
        'icon': 'fa-file'
    }, hasAccess("agreements", options.userInfo, "view") && {
        'label': t('tabs.agreement.list'),
        'link': '/' + facilityLink + '/agreement/list' + getSearchParams(options),
        'icon': 'fa-square-pen'
    }, hasAccess("classes", options.userInfo, "view") && options.settings && options.settings.allowClasses && {
        'label': t('tabs.class.list'),
        'link': '/' + facilityLink + '/class/list' + getSearchParams(options),
        'icon': 'fa-users'
    }, hasAccess("reports", options.userInfo, "view") && {
        'label': t('tabs.payment.list'),
        'link': '/' + facilityLink + '/payment/list' + getSearchParams(options),
        'icon': 'fa-money'
    }, hasAccess("booking", options.userInfo, "viewWithFinancials") && {
        'label': t('tabs.credit.list'),
        'link': '/' + facilityLink + '/credit/list' + getSearchParams(options),
        'icon': 'fa-credit-card'
    }, hasAccess("customers", options.userInfo, "update") && {
        'label': t('tabs.customer.profile'),
        'icon': 'fa-user',
        'modal': 'EditCustomerProfileModal',
        'params': options
    }, hasAccess("reports", options.userInfo, "view") && options.settings && options.settings.paymentProcessSetupComplete && options.settings.paymentProcessor === "STRIPE" && {
        'label': t('tabs.payment_methods.list'),
        'link': '/' + facilityLink + '/user/payment_methods' + getSearchParams(options),
        'icon': 'fa-credit-card'
    }, hasAccess("customers", options.userInfo, "update") && {
        'label': t('tabs.customer.notes'),
        'link': '/' + facilityLink + '/user/comments' + getSearchParams(options),
        'icon': 'fa-comment'
    }];

    if (options && options.userInfo && (options.userInfo.isSuperAdmin || isFullAdmin(options.userInfo)) && options.settings && options.settings.isPartnerFacility) {
        items.push({
            'label': "LeagueApps Sync",
            'link': '/' + facilityLink + '/partner/customersync' + getSearchParams(options),
            'icon': 'fa-rotate',
        })
    }

    return items;
}

function getVenueSettingsItems(t, facilityLink,  options) {
    return [{
        'label': t('tabs.venue_settings.settings', options),
        'link': '/' + facilityLink + '/setting/booking' + getSearchParams(options),
    }, {
        'label': t('tabs.venue_settings.hourly_rates', options),
        'link': '/' + facilityLink + '/setting/exception' + getSearchParams(options),
    }, {
        'label': t('tabs.venue_settings.event_types', options),
        'link': '/' + facilityLink + '/setting/eventtypelist' + getSearchParams(options),
    }, {
        'label': t('tabs.venue_settings.rental_policy', options),
        'link': '/' + facilityLink + '/setting/policy' + getSearchParams(options),
    }, {
        'label': t('tabs.venue_settings.block_sync', options),
        'link': '/' + facilityLink + '/setting/blockvenue' + getSearchParams(options),
    }, {
        'label': t('tabs.venue_settings.multi_book', options),
        'link': '/' + facilityLink + '/setting/multibooking' + getSearchParams(options),
    }, {
        'label': t('tabs.venue_settings.multi_group', options),
        'link': '/' + facilityLink + '/setting/multigroup' + getSearchParams(options),
    }];
}

function getBatchImportVenuesItems(t, facilityLink, options) {
    return [{
        'label': t('tabs.venue_settings.batch_import_venues'),
        'link': '/' + facilityLink + '/setting/batchimportvenues',
    }];
}

function getBatchImportUsersItems(t, facilityLink, options) {
    return [{
        'label': t('tabs.venue_settings.batch_import_users'),
        'link': '/' + facilityLink + '/user/batch_create',
    }];
}

function getBatchImportAccountsItems(t, facilityLink, options) {
    return [{
        'label': t('tabs.settings.batch_import_accounts'),
        'link': '/' + facilityLink + '/setting/accountlist/import',
    }];
}

function getImportBookingItems(t, facilityLink, options) {
    return [{
        'label': t('tabs.booking.import'),
        'link': '/' + facilityLink + '/booking/import',
    }];
}

function getMediaScreenItems(t, facilityLink,  options) {
    return [{
        'label': t('tabs.media_screen.media_screen'),
        'link': '/' + facilityLink + '/setting/mediascreen',
    }];
}

function getEmailSmsItems(t, facilityLink,  options) {
    return [{
        'label': t('tabs.email.system_emails'),
        'link': '/' + facilityLink + '/setting/emaillist',
    }, options.isSuperAdmin ? {
        'label': t('tabs.email.default_emails'),
        'link': '/' + facilityLink + '/setting/defaultemaillist',
    } : null
    ];
}

function getAccountsItems(t, facilityLink,  options) {
    return [{
        'label': t('tabs.accounts.accounts'),
        'link': '/' + facilityLink + '/setting/accountlist',
    }];
}

function getInvoicesItems(t, facilityLink,  options) {
    return [{
        'label': t('tabs.invoices.invoices'),
        'link': '/' + facilityLink + '/invoice/list' + getSearchParams(options),
    }, {
        'label': t('tabs.invoices.payment_list'),
        'link': '/' + facilityLink + '/invoice/paymentlist' + getSearchParams(options),
    }, {
        'label': t('tabs.invoices.aged_report'),
        'link': '/' + facilityLink + '/invoice/aged-report' + getSearchParams(options),
    }];
}

function getClassesItems(t, facilityLink,  options) {
    return [{
        'label': t('tabs.classes.classes'),
        'link': '/' + facilityLink + '/class/list' + getSearchParams(options),
    }];
}

function getAgreementsItems(t, facilityLink,  options) {
    return [{
        'label': t('tabs.agreements.agreements'),
        'link': '/' + facilityLink + '/agreement/list' + getSearchParams(options),
    }];
}

function getPaymentsItems(t, facilityLink,  options) {
    return [{
        'label': t('tabs.payment.list'),
        'link': '/' + facilityLink + '/payment/list' + getSearchParams(options),
    }];
}

function getLiveFeedItems(t, facilityLink,  options) {
    return [{
        'label': t('tabs.livefeed.livefeed'),
        'link': '/' + facilityLink + '/dashboard/livefeed' + getSearchParams(options),
    }];
}

function getCreditItems(t, facilityLink,  options) {
    return [{
        'label': t('tabs.credit.list'),
        'link': '/' + facilityLink + '/credit/list' + getSearchParams(options),
    }];
}

function getCustomersItems(t, facilityLink,  options) {
    let items = [{
        'label': t('tabs.customer.list'),
        'link': '/' + facilityLink + '/user/grouplist' + getSearchParams(options),
    }];

    if (options && options.userInfo && (options.userInfo.isSuperAdmin || isFullAdmin(options.userInfo)) && options.isPartnerFacility) {
        items.push({
            'label': "LeagueApps Import",
            'link': '/' + facilityLink + '/partner/customerimport' + getSearchParams(options),
            'icon': 'fa-download',
        })
    }

    return items;
}

function getTaxesItems(t, facilityLink,  options) {
    return [{
        'label': t('tabs.taxes.list'),
        'link': '/' + facilityLink + '/setting/tax' + getSearchParams(options),
    }];
}

function getPaymentMethodsItems(t, facilityLink,  options) {
    return [{
        'label': t('tabs.payment_methods.list'),
        'link': '/' + facilityLink + '/setting/paymenttypelist' + getSearchParams(options),
    }, {
        'label': t('tabs.payment_methods.integrations'),
        'link': '/' + facilityLink + '/setting/payment' + getSearchParams(options),
    }];
}

function getVenueSortItems(t, facilityLink,  options) {
    let items = [{
        'label': t('tabs.venue_sort.user', options),
        'link': '/' + facilityLink + '/setting/venuesortlist1' + getSearchParams(options),
    }, {
        'label': t('tabs.venue_sort.default', options),
        'link': '/' + facilityLink + '/setting/venuesortlist1?default=1' + getSearchParams(options, true),
    }];
    
    if (options && options.isPartnerFacility && options.userInfo && (options.userInfo.isSuperAdmin || isFullAdmin(options.userInfo))) {
        items.push({
            'label': t('tabs.partner.venue_sync', { partner: { name: "LeagueApps" } }),
            'link': '/' + facilityLink + '/setting/partner_venue_sync' + getSearchParams(options),
        });
        items.push({
            'label': t('tabs.partner.sites', { partner: { name: "LeagueApps" } }),
            'link': '/' + facilityLink + '/setting/partner_sites' + getSearchParams(options),
        });
    }

    return items;
}

function getInvAgrItems(t, facilityLink,  options) {
    return [{
        'label': t('tabs.inv_agr.settings'),
        'link': '/' + facilityLink + '/setting/agreement',
    }, {
        'label': t('tabs.inv_agr.agreement_templates'),
        'link': '/' + facilityLink + '/setting/agreement_templates',
    }];
}

function getNonAdminUserItems(t, facilityLink,  options) {
    return [{
        'label': t('tabs.user.profile'),
        'link': '/' + facilityLink + '/user/user_profile',
    }, {
        'label': t('tabs.user.payment_methods'),
        'link': '/' + facilityLink + '/user/payment_methods',
    }];
}

export function getTabItems(t, facilityLink, key, options = null) {
    if (key === "setting-classes") {
        return getClassSettingItems(t, facilityLink);
    } else if (key === "super-admin") {
        return getSuperAdminItems(t, facilityLink);
    } else if (key === "customer-types") {
        return getCustomerTypeItems(t, facilityLink);
    } else if (key === "locker-rooms") {
        return getLockerRoomItems(t, facilityLink);
    } else if (key === "setting") {
        return getSettingItems(t, facilityLink);
    } else if (key === "setting-admins") {
        return getAdminSettingItems(t, facilityLink, options); 
    } else if (key === "booking-search") {
        return getBookingSearchItems(t, facilityLink);
    } else if (_.includes(["bookings", "invoices", "classes", "agreements", "payments", "credit", "non-admin-user"], key)) {
        if (options && options.userInfo && isNotAdmin(options.userInfo)) {
            return getUserItems(t, facilityLink, options);
        } else if (options && options.groupId) {
            return getUserGroupItems(t, facilityLink, options);
        } else {
            if (key === "bookings") {
                return getBookingsItems(t, facilityLink, options);
            } else if (key === "invoices") {
                return getInvoicesItems(t, facilityLink, options);
            } else if (key === "classes") {
                return getClassesItems(t, facilityLink, options);
            } else if (key === "agreements") {
                return getAgreementsItems(t, facilityLink, options);
            } else if (key === "payments") {
                return getPaymentsItems(t, facilityLink, options);
            } else if (key === "credit") {
                return getCreditItems(t, facilityLink, options);
            } else if (key === "non-admin-user") {
                return getNonAdminUserItems(t, facilityLink, options);
            } else {
                console.log("No tabs for " + key);
            }
        }
    } else if (key === "addons") {
        return getAddOnItems(t, facilityLink);
    } else if (key === "venue-settings") {
        return getVenueSettingsItems(t, facilityLink, options);
    } else if (key === "media-screen") {
        return getMediaScreenItems(t, facilityLink, options);
    } else if (key === "email-sms") {
        return getEmailSmsItems(t, facilityLink, options);
    } else if (key === "accounts") {
        return getAccountsItems(t, facilityLink, options);
    } else if (key === "livefeed") {
        return getLiveFeedItems(t, facilityLink, options);
    } else if (key === "customers") {
        return getCustomersItems(t, facilityLink, options);
    } else if (key === "taxes") {
        return getTaxesItems(t, facilityLink, options);
    } else if (key === "payment-methods") {
        return getPaymentMethodsItems(t, facilityLink, options);
    } else if (key === "venue-sort") {
        return getVenueSortItems(t, facilityLink, options);
    } else if (key === "batch-import-venues") {
        return getBatchImportVenuesItems(t, facilityLink, options)
    } else if (key === "batch-import-users") {
        return getBatchImportUsersItems(t, facilityLink, options)
    } else if (key === "batch-import-accounts") {
        return getBatchImportAccountsItems(t, facilityLink, options)
    } else if (key === "inv-agr") {
        return getInvAgrItems(t, facilityLink, options);
    } else if (key === "import-booking") {
        return getImportBookingItems(t, facilityLink, options);
    }   else {
        console.log("No tabs for " + key);
    }
    return [];
}
