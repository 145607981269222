import '../App.scss';
import '../css/modals.scss';
import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, createRef, useMemo } from 'react';
import BaseModal from './modals/BaseModal';
import BaseForm from './BaseForm';
import SubmitButton from './SubmitButton';
import { Row, Col } from 'react-bootstrap';
import { BaseContext, getStripePublishableKeyForFacility } from '../helpers/common';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement, CardElement } from '@stripe/react-stripe-js';
import classnames from 'classnames';
const _ = require('lodash');

const StripeCardInput = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        getPaymentMethod(data) {
            return getPaymentMethod(data);
        },
        onFieldChange(name, value) {
            onFieldChange(name, value);
        }
    }));

    const { t } = useTranslation('common');
    const { getApiUrl, settings } = useContext(BaseContext);
    const formRef = createRef();

    const getPaymentMethod = async (data) => {
        if (formRef.current) {
            const result = await formRef.current.getPaymentMethod(data);
            if (result) {
                return {
                    paymentMethodId: result.id
                };
            } else {
                return null;
            }
        }
        return null;
    }

    const onFieldChange = (name, value) => {
        if (props.setInitialFields) {
            if (_.includes(["cardHolderName", "zipcode"], name)) {
                props.setInitialFields(prevInitialFields => {
                    const newInitialFields = {...prevInitialFields};
                    newInitialFields[name] = value;
                    return newInitialFields;
                });
            }
        }
    }

    const stripePromise = useMemo(() => {
        return loadStripe(getStripePublishableKeyForFacility(settings));
    }, [settings]);
    return (
        <Row>
            <BaseForm.Input colSpan="5" type="text" name="cardHolderName" label="Full Name (on card)" required />
            <Col md="8">
                <Elements stripe={stripePromise}>
                    <CardForm ref={formRef} />
                </Elements>
            </Col>
            <Col md="4" className="d-flex align-items-center">
                <BaseForm.Input colSpan="12" type="check" name="save_card" label="Save Card (for future use)" required />
            </Col>
        </Row>
    );

})

const CardForm = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        getPaymentMethod(data) {
            return getPaymentMethod(data);
        },
    }));

    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState("");

    const getPaymentMethod = async (data) => {
        if (!stripe || !elements) {
            return;
        }

        if (!_.isEmpty(errorMessage)) {
            return;
        }

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                name: data.name,
            },
        });
        return result.paymentMethod;
    };

    const handleChange = (event) => {
        if (event.error) {
            setErrorMessage(event.error.message)
        } else {
            setErrorMessage("");
        }
    }

    const cardOptions = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                "::placeholder": {
                    color: "#626262",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };
    return (
        <div>
            <BaseForm.InputGroup className={classnames(_.isEmpty(errorMessage) ? "": "error")}>
                <BaseForm.Label>Card Details</BaseForm.Label>
                <CardElement options={cardOptions} onChange={handleChange}/>
                {
                    errorMessage &&
                        <p className="form-error-message text-start">{errorMessage}</p>
                }
            </BaseForm.InputGroup>
        </div>
    )
})

export default StripeCardInput;
