import Papa from "papaparse";
import { useContext, useEffect, useState } from "react";
import { Button, Row, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BaseForm from "../../components/BaseForm";
import BaseContainer from "../../components/Container";
import Loader from "../../components/Loader";
import TabHeader from "../../components/TabHeader";
import { BaseContext, validateEmail } from "../../helpers/common";
import { serverPost } from "../../helpers/server";
import { getTabItems } from "../../helpers/tabs";
import { CSVLink } from "react-csv";
import LoadingModal from "../../components/modals/LoadingModal";
import { serverFetch } from "../../helpers/server";
const _ = require("lodash");


function ImportAccounts() {
  const { getApiUrl, facilityLink, isLoggedIn } = useContext(BaseContext);
  const { t } = useTranslation("common");

  const [users, setUsers] = useState({});
  const [rawCsvData, setRawCsvData] = useState([]);
  const [validData, setValidData] = useState([]);
  const [dataErrors, setDataErrors] = useState([]);
  const [invalidData, setInvalidData] = useState([]);
  const [improperFileError, setImproperFileError] = useState(false);
  const [importError, setImportError] = useState(false);
  const [formattingError, setFormattingError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);

  useEffect((skipCache = false) => {
    setLoading(true);
    serverFetch(getApiUrl('/accounts'), { skipCache }).then((res) => {
      setAccounts(_.flatMap(res, account => {
        if (account.parentAccountId === null) {
          return account.account;
        } else {
          return [];
        }
      }))

      console.log(accounts);

      setLoading(false);
  })
  }, []);

  const updateErrors = (res) => {
    if (res["error"]) {
      setImportError(true);
    }

    const rowsToAddToInvalid = [];
    const rowsToRemoveFromValid = [];
    const newDataErrors = [];

    res.forEach((row, rowIndex) => {
      if (row.success === false) {
        setFormattingError(true);
        const newDataError = {};
        Object.entries(row.errorMessage).forEach(([key, value]) => {
          newDataError[key] = value;
        });

        newDataErrors.push(newDataError);
        rowsToAddToInvalid.push(validData.accounts[rowIndex]);
        rowsToRemoveFromValid.push(rowIndex);
      } else {
        newDataErrors.push({});
        rowsToAddToInvalid.push();
        rowsToRemoveFromValid.push(rowIndex);
      }
    });

    // Remove rows with errors from validData
    const updatedValidData = validData.accounts.filter(
      (_, index) => !rowsToRemoveFromValid.includes(index)
    );

    // Update validData and invalidData states
    setDataErrors(newDataErrors);
    setValidData(updatedValidData);
    setInvalidData((prevInvalidData) => [
      ...prevInvalidData,
      ...rowsToAddToInvalid,
    ]);
  };

  const batchImportAccounts = async (data) => {
    const data_without_row = data.accounts.map(({ row, ...rest }) => rest);

    const chunkArray = (array, chunkSize) => {
      const chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
    };

    const userChunks = chunkArray(data_without_row, 100);
    const responses = [];

    setUploading(true);

    for (const chunk of userChunks) {
      const chunkData = { accounts: chunk };
      try {
        const res = await serverPost(
          getApiUrl("/accounts/batch_import"),
          chunkData
        );
        console.log(res);
        if (res) {
          responses.push(...res);
        } else {
          setImportError(true);
        }
      } catch (error) {
        setImportError(true);
      }
    }
    updateErrors(responses);
    setUploading(false);
    setUploaded(true);
  };

  const csvTemplate = [
    [
      "Account Name (Required)",
      "Parent Account Name",
    ],
  ];

  const tableHeaders = [
    "Row",
    "Account Name (Required)",
    "Parent Account Name",
  ];

  const formatData = (csvData) => {
    const csvColumns = csvData[0];
    const filteredCsvColumns = csvColumns.filter(column => column.trim() !== '');

    // There are not 7 columns or there is not any data (header column takes up one row so there needs to be at least 2 rows to have data)
    if (filteredCsvColumns.length !== 2 || csvData.length < 2) {
      setImproperFileError(true);
      return;
    }

    const formattedData = {};
    formattedData["accounts"] = [];

    const dataErrors = [];
    const invalidData = [];

    const accountNameSet = new Set(accounts);

    console.log(accountNameSet);

    csvData.forEach((row, rowIndex) => {
      // this is the headers row
      if (rowIndex === 0) {
        return;
      }

      let rowFormattingError = false;

      const formattedRow = {};
      const dataErrorsRow = {};

      formattedRow["row"] = rowIndex;

      row.forEach((cell, colIndex) => {
        // Group name is required and has to be unique
        if (colIndex === 0) {
          if (cell.trim() === "") {
            dataErrorsRow["accountName"] = "Account name is empty";
            formattedRow["accountName"] = null;
            rowFormattingError = true;
          } else if (accountNameSet.has(cell)) {
            dataErrorsRow["accountName"] =
              "Account name is already used in this batch";
            formattedRow["accountName"] = cell;
            rowFormattingError = true;
          } else {
            accountNameSet.add(cell);
            dataErrorsRow["accountName"] = null;
            formattedRow["accountName"] = cell;
          }
        }
      });

      row.forEach((cell, colIndex) => {
        if (colIndex === 1) {
          if (cell.trim() !== "") {
            if (!accountNameSet.has(cell)) {
              dataErrorsRow["parentAccountName"] =
                "Parent account name does not exist";
              formattedRow["parentAccountName"] = cell;
              rowFormattingError = true;
            } else {
              dataErrorsRow["parentAccountName"] = null;
              formattedRow["parentAccountName"] = cell;
            }
          } else {
            dataErrorsRow["parentAccountName"] = null;
            formattedRow["parentAccountName"] = null;
          }
        }
      });

      if (rowFormattingError) {
        dataErrors.push(dataErrorsRow);
        invalidData.push(formattedRow);
        setFormattingError(true);
      } else {
        formattedData["accounts"].push(formattedRow);
      }
    });

    console.log(formattedData);
    setValidData(formattedData);
    setInvalidData(invalidData);
    setDataErrors(dataErrors);
  };

  const onFieldChange = (name, file) => {
    if (name === "csv" && file != null) {
      setImproperFileError(false);
      setUploading(false);
      setUploaded(false);
      setUploadSuccess(false);
      setFormattingError(false);
      setImportError(false);
      setRawCsvData([]);
      setValidData([]);
      setInvalidData([]);
      setDataErrors([]);

      Papa.parse(file, {
        skipEmptyLines: true,
        complete: (results) => {
          setRawCsvData(results.data);
        },
      });
    }
  };

  useEffect(() => {
    if (rawCsvData.length > 0) {
      setLoading(true);
      formatData(rawCsvData);
      setLoading(false);
    }
  }, [rawCsvData]);

  useEffect(() => {
    if (importError === false && uploaded === true) {
      setUploadSuccess(true);
    }
  }, [importError, uploaded]);

  return (
    <BaseContainer>
      <TabHeader items={getTabItems(t, facilityLink, "batch-import-accounts")} />
      <BaseForm
        initialFormFields={users}
        onFieldChange={onFieldChange}
        onSubmit={batchImportAccounts}
      >
        <div className="content-box">
          <div className="content-header">
            <span className="content-title">
              {t("venue_settings.csv_upload")}
            </span>
          </div>

          <div className="content-body">
            <span>{t("users.download_template")}</span>
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <CSVLink
                data={csvTemplate}
                filename={"CSV-Batch-Import-Accounts-Template.csv"}
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                {t("venue_settings.download_template")}
              </CSVLink>
            </div>

            <ul>
              <li>{t("setting.accounts.account_name")}</li>
              <li>{t("setting.accounts.parent_account_name")}</li>
            </ul>

            <Row>
              <BaseForm.Input
                colSpan="6"
                type="file"
                name="csv"
                label="Import CSV"
                accept=".csv"
                fileType="invoice_logo"
              />
            </Row>

            <br />

            <div>
              <Loader loading={uploading}>
                <div>
                  <Button
                    disabled={
                      validData.length === 0 ||
                      uploaded ||
                      formattingError ||
                      improperFileError
                    }
                    onClick={() => batchImportAccounts(validData)}
                  >
                    Upload
                  </Button>
                  {(improperFileError || formattingError) && !uploadSuccess && (
                    <span style={{ color: "red", marginLeft: "15px" }}>
                      {t("users.fix_errors_before_uploading")}
                    </span>
                  )}

                  {uploadSuccess && !(improperFileError || formattingError) && (
                    <span style={{ color: "green", marginLeft: "15px" }}>
                      {t("users.upload_successful")}
                    </span>
                  )}

                  {uploadSuccess && (improperFileError || formattingError) && (
                    <span style={{ color: "red", marginLeft: "15px" }}>
                      {t("users.upload_successful_but_error")}
                    </span>
                  )}

                  {importError && (
                    <span style={{ color: "red", marginLeft: "15px" }}>
                      {t("users.upload_failed")}
                    </span>
                  )}
                </div>
              </Loader>
            </div>
          </div>
        </div>

        {invalidData.length === 0 && validData.length === 0 && (
          <div className="content-box">
            <div className="content-header">
              <span className="content-title">{t("users.csv_data")}</span>
            </div>
            <div className="content-body">
              <Loader loading={loading}>
                {!improperFileError && validData.length === 0 && (
                  <span>{t("users.csv_loaded_here")}</span>
                )}
                {improperFileError && (
                  <span>{t("users.file_incorrect_format")}</span>
                )}
              </Loader>
            </div>
          </div>
        )}

        {invalidData.length > 0 && formattingError && (
          <div className="content-box">
            <div className="content-header">
              <span className="content-title">
                {t("users.invalid_csv_data")}
              </span>
            </div>
            <div className="content-body">
              <Loader loading={loading}>
                <Table>
                  <thead>
                    <tr>
                      {tableHeaders.map((header) => (
                        <th key={header}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {invalidData.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.entries(row).map(([key, cell], colIndex) => (
                          <td key={colIndex}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span> {cell && cell} </span>
                              {dataErrors[rowIndex][key] && (
                                <span style={{ color: "red" }}>
                                  {dataErrors[rowIndex][key]}
                                </span>
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Loader>
            </div>
          </div>
        )}

        {validData.accounts && validData.accounts.length > 0 && (
          <div className="content-box">
            <div className="content-header">
              <span className="content-title">{t("users.valid_csv_data")}</span>
            </div>
            <div className="content-body">
              <Loader loading={loading}>
                <Table>
                  <thead>
                    <tr>
                      {tableHeaders.map((header) => (
                        <th key={header}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {validData.accounts.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.entries(row).map(([key, cell], colIndex) => (
                          <td key={colIndex}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span> {cell && cell} </span>
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Loader>
            </div>
          </div>
        )}
      </BaseForm>

      <LoadingModal show={uploading} />
    </BaseContainer>
  );
}

export default ImportAccounts;
